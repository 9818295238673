import React, { Fragment } from 'react'
import { useField } from '@unform/core'
import { Circle, CheckCircle } from 'react-feather';


//
export default function InputCheckbox({name, value, label, ...props}) {

    const inputRef = React.useRef(null);
    const { fieldName, defaultValue, registerField, error, clearError } = useField(name)
    const defaultChecked = defaultValue === value

    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.checked
            },
            setValue: (ref, value) => {
                ref.current.checked = value
            },
            clearValue: ref => {
                ref.current.checked = defaultChecked
            },
        })
    }, [defaultValue, fieldName, registerField, defaultChecked])


    //
    //
    return (
        <Fragment>
            <div className="formGroup">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    className={`formControl ${error ? 'error' : ''}`}
                    ref={inputRef}
                    id={fieldName}
                    defaultChecked={defaultChecked}
                    value={value}
                    onFocus={clearError} {...props}
                    {...props}
                  />
                  <div>
                    <CheckCircle className="checked" color="#94c943"/>
                    <Circle className="unchecked" color="#999"/>
                  </div>
                  {label && <label>{label}</label>}
                </div>
                { error && <span className="error">{error}</span> }
            </div>
        </Fragment>
    );
};