import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-nestable/dist/styles/index.css';
import './assets/scss/style.css';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import App from './App';


//
//
// Sentry.init({
//   dsn: "https://746d837b2ac747e7956656e3e35de34d@o993999.ingest.sentry.io/6066592",
//   integrations: [new Integrations.BrowserTracing()],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 0.1,
// });


//
//
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);