import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom';


// components
import Layout from '../components/Layout'


export default function Page404(props){

  const history = useHistory()


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>404 not found | {process.env.REACT_APP_NAME}</title>
      </Helmet>


      <Layout className="errorPage">

              <div className="container mx-auto">
                <div className="box mx-auto p-8 w-5/6 md:w-1/2 lg:w-[458px]">

                  <h1 className="text-center text-4xl">404</h1>
                  <h3 className="h3 text-center">Page not found</h3>
                  <p className="text-center mt-5 mb-8">The requested page does not exist, or may have been deleted.</p>
                  
                  <div className="text-center">
                    <button type="button" onClick={()=> history.goBack()} className="button primary w-full">Back to the start page</button>
                  </div>

                </div>
              </div>

        </Layout>

    </Fragment>
  );

}
