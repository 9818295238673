import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs'
import { ChevronLeft } from 'react-feather';



// components
import Layout from '../../../components/Layout'
import Loading from '../../../components/Loading'
import Sidebar from '../../../components/Sidebar'
import TopBar from '../../../components/TopBar'


// controllers
import { _apiGETone, _apiPUT } from '../../../controllers/Api';
import { _status, _toaster } from '../../../controllers/Helpers'


//
//
export default function PagePayoutDetails(props){

    const auth  = useSelector(state => state.auth);
    const history = useHistory()
    const { id } = useParams();

    const [ isLoading, setLoading ] = React.useState(true);
    const [ responsData, setResponseData ] = React.useState([]);
    const [ currentBalance, setCurrentBalance ] = React.useState(0);
    

    const [ isSubmitted, setIsSubmitted ] = React.useState('none')
    const [ textareaMessage, setTextareaMEssage ] = React.useState('')


    //
    //
    const _query = async() => {

        setLoading(true)

        const get = await _apiGETone('/transactions/payout/'+id)
        if(!get.error){
            setResponseData(get.data);
            setCurrentBalance(get.data.userData.balanceRegular+get.data.total+1)
        }

        setLoading(false)
    }


    //
    //
    const _reject = async() => {

        const update = await _apiPUT('/transactions/payouts-update/', {
            _id: responsData._id,
            userId: responsData.userId,
            userData: responsData.userData,
            message: textareaMessage,
            total: responsData.total,
            status: 'rejected'
        })

        if(update.error){
            _toaster('Error', update.error.message, 'error')
            return
        }

        _toaster('Error', update.message, 'error')
        setResponseData({...responsData, status:'rejected', dateUpdated:update.dateUpdated})
  
    }
  
  
    //
    //
    const _approve = async() => {

        const update = await _apiPUT('/transactions/payouts-update/', {
            _id: responsData._id,
            userId: responsData.userId,
            userData: responsData.userData,
            message: textareaMessage,
            total: responsData.total,
            status: 'approved'
        })

        if(update.error){
            _toaster('Error', update.error.message, 'error')
            return
        }

        if(update.status==='Success'){
            _toaster('Success', update.message, 'success')
            setResponseData({...responsData, status:'approved', dateUpdated:update.dateUpdated})
            return
        }
  
    }


    //
    //
    React.useEffect(()=>{
        _query();
    },[]);


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>{`Payout #${!isLoading && responsData._id} - Transactions | ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />
                
                {!isLoading ? 
                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Transactions / Payout request"
                        title={'#'+responsData._id}
                    >
                        <a href="#" className="button dark sm ml-2" onClick={()=> history.goBack()}><ChevronLeft/> Back</a>
                    </TopBar>
                    <div className="_inner">
                        {/* START */}

                        <div className="box p-6 my-4">
                            <div className="flex felx-col gap-6">
                                <div className="w-1/2">
                                    <small>Request ID</small>
                                    <h4 className="font-bold text-xl">{responsData._id}</h4>
                                    <div className="mb-5">{_status(responsData.status)}</div>
                                    <div className="grid grid-cols-2 gap-6">
                                        <div>
                                            <small>Requested payout</small>
                                            <h4 className="font-bold text-2xl">{process.env.REACT_APP_CURRENCY+(responsData.total).toFixed(2)}</h4>
                                            <small>{process.env.REACT_APP_CURRENCY}1.00 transaction fee is automatically deducted</small>
                                        </div>
                                        {responsData.status === 'pending' && 
                                        <div>
                                            <small>Current balance</small>
                                            <h4 className={`font-bold text-2xl ${currentBalance<responsData.total ? 'text-red-500' : ''}`}>{process.env.REACT_APP_CURRENCY+(currentBalance).toFixed(2)}</h4>
                                        </div>
                                        }
                                    </div>

                                    <div className="mt-4">
                                    {responsData.status === 'pending' && 
                                    <>
                                    {isSubmitted === 'none' && 
                                        <div className="grid grid-cols-2 gap-4">
                                            <button className="button primary w-full my-2" onClick={()=> setIsSubmitted('approve')}>Approve</button>
                                            <button className="button secondary w-full my-2" onClick={()=> setIsSubmitted('reject')}>Reject</button>
                                        </div>
                                    }
                                    {isSubmitted === 'reject' &&
                                        <div>
                                        <div className="formGroup"><textarea name="message" className="formControl" value={textareaMessage} onChange={text => setTextareaMEssage(text.target.value)}/></div>
                                        <button className="button dark w-full my-2" onClick={()=> _reject()}>Submit rejection</button>
                                        </div>
                                    }
                                    {isSubmitted === 'approve' &&
                                        <div className="grid grid-cols-2 gap-4">
                                        <button className="button dark w-full my-2" onClick={()=> _approve()}>Confirm</button>
                                        <button className="button secondary w-full my-2" onClick={()=> setIsSubmitted('none')}>Cancel</button>
                                        </div>
                                    }
                                    </>
                                    }
                                    </div>
                                </div>
                                <div className="w-1/4"></div>
                                <div className="w-1/4">
                                    <small>Closet name</small>
                                    <h4 className="font-bold mb-5">{responsData.userData.storeName}</h4>
                                    <small>Created</small>
                                    <h4 className="mb-5">{dayjs(responsData.dateCreated).format('DD.MM.YYYY HH:mm')}</h4>
                                    {responsData.status !== 'pending' && 
                                    <>
                                        <small>Processing date:</small>
                                        <h4>{dayjs(responsData.dateUpdated).format('DD.MM.YYYY HH:mm')}</h4>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="p-6 my-4">
                            <h4 className="font-bold text-xl mb-6">Seller details</h4>
                            <div className="grid grid-cols-4 gap-6">
                                <div>
                                    <small>First name</small>
                                    <h4>{responsData.userData.firstName}</h4>
                                </div>
                                <div>
                                    <small>Last name</small>
                                    <h4>{responsData.userData.lastName}</h4>
                                </div>
                                <div>
                                    <small>Email</small>
                                    <h4>{responsData.userData.email}</h4>
                                </div>
                                <div>
                                    <small>Phone</small>
                                    <h4>{responsData.userData.phone}</h4>
                                </div>
                                <div>
                                    <small>Account number</small>
                                    <h4>{responsData.userData.payoutInfo?.accountNumber}</h4>
                                </div>
                                <div>
                                    <small>Bank name</small>
                                    <h4>{responsData.userData.payoutInfo?.bankName}</h4>
                                </div>
                                <div>
                                    <small>Beneficiary name</small>
                                    <h4>{responsData.userData.payoutInfo?.beneficiaryName}</h4>
                                </div>
                                <div>
                                    <small>Sort code</small>
                                    <h4>{responsData.userData.payoutInfo?.sortCode}</h4>
                                </div>
                            </div>
                        </div>

                        {/* END */}
                    </div>
                </div>
                :
                    <Loading status={isLoading}/>
                }
            </Layout>

        </Fragment>
    );

}
