import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import { Loader, ChevronLeft, ChevronRight, Info, Plus, AlertTriangle, ShoppingBag, XOctagon, Save } from 'react-feather';
import { Form } from '@unform/web'
import * as Yup from 'yup';
import Nestable from 'react-nestable';



// components
import Layout from '../../components/Layout'
import Sidebar from '../../components/Sidebar'
import TopBar from '../../components/TopBar'
import Input from '../../components/Forms/Input'
import TextArea from '../../components/Forms/TextArea'
import SingleSelect from '../../components/Forms/Select'
import InputCheckbox from '../../components/Forms/Checkbox'
import ContentEditorBasic from '../../components/ContentEditor/basic'
import BarCode from '../../components/BarCode'
import Media from '../../components/Media';


// controllers
import { _apiGETone, _apiGETmulti, _apiPUT, _apiPOST } from '../../controllers/Api';
import { _status as _Status, _toaster } from '../../controllers/Helpers'


//
//
export default function PageProductDetails(props){

    const auth  = useSelector(state => state.auth);
    const formRef = React.useRef(null);
    const history = useHistory()
    const { id } = useParams();

    const [ isLoading, setLoading ] = React.useState(true);
    const [ responsData, setResponseData ] = React.useState(null);
    const [ sellerData, setSellerData ] = React.useState(null);
    const [ attributes, setAttributes ] = React.useState(null);
    const [ categories, setCategories ] = React.useState(null);

    const groups = [
        {_id:'regular', title:'Regular'},
        {_id:'bazaar', title:'Bazaar'}
    ]
    const statuses = [
        {_id:'pending', title:'Pending'},
        {_id:'rejected', title:'Rejected'},
        {_id:'active', title:'Active'},
        {_id:'sold', title:'Sold'}
    ]

    //
    const [ description, setDescription ] = React.useState('')
    const [ featuredInCloset, setFeaturedInCloset ] = React.useState(false)
    const [ gallery, setGallery ] = React.useState([]);
    const [ bundle, setBundle ] = React.useState(null);
    const [ featuredImage, setFeaturedImage ] = React.useState('');

    //
    // search producs for looks
    const node = React.useRef();
    const [searchProduct, setSearchProduct] = React.useState('');
    const [searchIsOpen, setSearchIsOpen ] = React.useState(false);
    const [matchedProducts, setMatchedPRoducts] = React.useState([]);
    const [selectedProducts, setSelectedPRoducts] = React.useState([]);
    const [searchLoading, setSearchLoading] = React.useState(false);


    //
    // Media modal
    const [mediaIndex, setMediaIndex] = React.useState(null);
    const [mediaState, setMediaState] = React.useState(false);
    const [mediaType, setMediaType] = React.useState('featured');
    const _mediaOpen = (i,type) => {
        setMediaIndex(i);
        setMediaState(true);
        setMediaType(type);
    }
    const _mediaClose = () => {
        setMediaIndex(null);
        setMediaState(false);
    }
    const _insertMedia = (index, image) => {
        _mediaClose();
        if(mediaType=='featured'){
            setFeaturedImage(image[0].fileName);
        }else{
            let gall = gallery;
            image.map((img,i)=>{
                gall.push({fileName:img.fileName, _id:img._id});
            });
            setGallery(gall);
        }
    }


    //
    //
    const _handleEditorChange = (content, editor) => {
        setDescription(content)
    }


    //
    //
    const _setDefaults = () => {
        if(responsData && id !== 'new'){

            formRef.current.setFieldValue('title', responsData.title);
            formRef.current.setFieldValue('status', statuses.find(x => x._id===responsData.status));
            formRef.current.setFieldValue('group', groups.find(x => x._id===responsData.group));
            formRef.current.setFieldValue('sku', responsData.sku);
            formRef.current.setFieldValue('price', responsData.price);
            formRef.current.setFieldValue('qty', responsData.qty);
            formRef.current.setFieldValue('weight', responsData.weight);

            setDescription(responsData.description)
            setFeaturedInCloset(responsData.featuredInCloset)
            setGallery(responsData.gallery)
            setFeaturedImage(responsData.featuredImage)
            setBundle(responsData.bundle)

            // seo
            if(responsData.seo && responsData.seo.length>0){
                formRef.current.setFieldValue('seoTitle', responsData.seo[0].title);  
                formRef.current.setFieldValue('seoKeywords', responsData.seo[0].keywords);  
                formRef.current.setFieldValue('seoDescription', responsData.seo[0].description);  
            }

            // set attributes
            if(responsData.attributes && responsData.attributes.length>0 && attributes){
                responsData.attributes.map((item,index)=>{
                    const attrType = attributes.find(x => x.permalink===Object.keys(item)[0])
                    // console.log('attrType',attrType)
                    if(attrType && attrType.values && attrType.values.length>0){
                    formRef.current.setFieldValue('attributes['+Object.keys(item)[0]+']', attrType.values.find(x => x.value===Object.values(item)[0]));
                    }
                })
            }

            // set categories
            if(responsData.category && responsData.category.length>0 && categories){
                responsData.category.map((item,index)=>{
                    const hasCat = categories.find(x => x._id===item._id)
                    if(hasCat){
                        formRef.current.setFieldValue('category['+hasCat._id+']', true);
                    }
                    categories.map((sub1,i1)=>{
                        if(sub1.children && sub1.children.length>0){
                            const hasCat2 = sub1.children.find(x => x._id===item._id)
                            if(hasCat2){
                                formRef.current.setFieldValue('category['+hasCat2._id+']', true);
                            }
                            sub1.children.map((sub2,i2)=>{
                                if(sub2.children && sub2.children.length>0){
                                    const hasCat3 = sub2.children.find(x => x._id===item._id)
                                    if(hasCat3){
                                        formRef.current.setFieldValue('category['+hasCat3._id+']', true);
                                    }
                                }
                            })
                        }
                    })
                })
            }

        }
    }


    //
    //
    const _getSeller = async(sellerId) => {
        const get = await _apiGETone('/user/seller-info/'+sellerId)
        if(!get.error){
            setSellerData(get.data);
        }
    }
    


    //
    //
    const _getAttributes = async() => {
        const get = await _apiGETone('/shop/attributes/query/', {limit: 100})
        if(!get.error && get.total>0){
            setAttributes(get.data);
        }
    }


    //
    //
    const _getCategories = async() => {
        const get = await _apiGETmulti('/taxonomies/query/', {type: 'products'})
        if(!get.error){
            setCategories(get);
        }
    }


    //
    //
    const _query = async() => {

        setLoading(true)

        if(id==='new'){
            setResponseData({type:'bundle', bundle:[]});
            await _getAttributes()
            await _getCategories()
        }else{
            const get = await _apiGETone('/shop/products/single/'+id)
            if(!get.error){
                setResponseData(get.data);
                await _getSeller(get.data.ownerId)
                await _getAttributes()
                await _getCategories()
                
            } 
        }
        setLoading(false)
    }


    //
    //
    React.useEffect(()=>{
        _query();
    },[]);


    //
    //
    React.useEffect(()=>{
        _setDefaults()
    },[responsData, attributes, categories]);


    //
    //
    const _renderAttributes = (item, index) =>{
        return <SingleSelect name={'attributes['+item.permalink+']'} data={item.values} label={item.title} key={index} getOptionValue={(option) => option.id}/>
    }


    //
    //
    const _rendercategories = (items, level=0) =>{
        return items.map((item,index)=>{
            return (
                <div key={index} className={`block ${level>0 ? 'ml-5' : ''}`}>
                    <InputCheckbox name={'category['+item._id+']'} label={item.title} value={item._id}/>
                    {item.children && item.children.length>0 && _rendercategories(item.children, level+1)}
                </div>
            )
        })
    }


    //
    const _prepareForSubmitCategories = async(current, categories) => {

        let formCategories = []
        const selectedCategories = Object.entries(current).filter(x => x[1]===true)
        categories.map((item1,i1)=>{
            const check1 = selectedCategories.find(x => x[0]===item1._id)
            if(check1){
                formCategories.push({_id:item1._id, title:item1.title})
            }
            if(item1.children && item1.children.length>0){
                item1.children.map((item2,i2)=>{
                    const check2 = selectedCategories.find(x => x[0]===item2._id)
                    if(check2){
                        formCategories.push({_id:item2._id, title:item2.title})
                    }
                    if(item2.children && item2.children.length>0){
                        item2.children.map((item3,iii32ndex)=>{
                            const check3 = selectedCategories.find(x => x[0]===item3._id)
                            if(check3){
                                formCategories.push({_id:item3._id, title:item3.title})
                            }
                        })
                    }
                })
            }
        })

        return await Promise.all(formCategories).then((results) => {
            return results;
        });
    }



    //
    //
    const handleSubmit = React.useCallback(async(data) => {
        try {
    
            
            // Remove all previous errors
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                title: Yup.string().required(),
                sku: Yup.string().required(),
                price: Yup.number('Price must be number').required(),
                weight: Yup.number('Weight must be number').required(),
                qty: Yup.number('QTY must be number').required()
            });
            await schema.validate(data, {
                abortEarly: false,
            });

            let formData = {...responsData, ...data}
            formData.gallery = gallery
            formData.featuredImage = featuredImage
            formData.description = description
            formData.featuredInCloset = featuredInCloset

            formData.status = data.status._id
            formData.group = data.group._id

            // configure categories
            
            formData.category = categories ? await _prepareForSubmitCategories(data.category, categories) : null

            // configure attributes
            let formAttributes = []
            const selectedAttributes = Object.entries(data.attributes).filter(x => x[1] !== null)
            console.log('selectedAttributes',selectedAttributes)
            selectedAttributes.map((item,index)=>{
                formAttributes.push({[item[0]]:item[1].value})
            })
            formData.attributes = formAttributes

            // seo
            formData.seo = [
                {title:data.seoTitle, keywords:data.seoKeywords, description:data.seoDescription}
            ]

            // bundle
            formData.bundle = bundle

            console.log('submit', formData)
        
            setLoading(true);
            
            if(id==='new'){

                formData.type = 'bundle'
                
                const insert = await _apiPOST('/shop/products/insert/', formData)
                if(!insert.error){
                    _toaster('Success', insert.message, 'success')
                    history.goBack()
                }


            }else{

                const update = await _apiPUT('/shop/products/update/'+id, formData)
                if(!update.error){
                    _toaster('Success', update.message, 'success')
                    history.goBack()
                }

            }
            setLoading(false);

            // const login = await _apiPOST('/auth/', {...data} ,false)
            // if(login.error){
            //     _toaster(login.error.status, login.error.message, 'error')
            //     setLoading(false);
            // }
            // if(login.user){
            //     if(login.user.data.role>3){
            //         _toaster('403 | Access denied', 'You dont have permission to access requested page.', 'error')
            //         return
            //     }
            //     const state = dispatch(_actions.auth.authUpdate({...login.user.data, token:login.user.token}))
            //     if(state){
            //         _log('state',state)
            //         await _timeout(500)
            //         history.push('/dashboard/')
            //     }
            //     setLoading(false);
            // }
    
        } catch (err) {
    
          const validationErrors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
                console.log(error.path,error.message)
              validationErrors[error.path] = error.message;
            });
            formRef.current.setErrors(validationErrors);
          }
    
        }
    }, [history, categories, gallery, featuredImage, description, featuredInCloset, bundle]);


    //
    //
    const _submit = () => {
        formRef.current.submitForm()
    } 


    //
    //
    const renderGalleryItem = ({item, i}) => {

        return (
        <div className="item" key={'item'+i}>
            <img src={process.env.REACT_APP_UPLOAD_URL+'/'+item.fileName+'_400.jpg'} alt=""/>
            <div className="overlay">
            <button type="button" onClick={()=>{ _removeGalleryItem(item._id ? item._id : item.id) }} className="bg-transparent border-0 cursor-pointer text-center">remove</button>
            <small>- OR -</small>
            <button type="button" onClick={()=>{ setFeaturedImage(item.fileName) }} className="bg-transparent border-0 cursor-pointer text-center">set as featured</button>
            </div>
        </div>
        );

    }


    //
    //
    const _removeGalleryItem = (id) => {
        let newArray = gallery;
        let filtered = newArray.filter((item)=>{
        return item._id !== id;
        });
        console.log(newArray, filtered, id)
        setGallery(filtered);
    }




    




    //
    //
    const _getSearch = async() => {

        setSearchLoading(true);

        const searching = await _apiGETmulti('/public/query-products/', {
            filter: {
                search: searchProduct
            },
            limit:5
        })

        if(!searching.error){

            if(searching.total !== 0){
                if(responsData.bundle && responsData.bundle.length>0){
                    let resData = []
        
                    for( var i=searching.data.length - 1; i>=0; i--){
                        if(searching.data[i]._id === id){
                            searching.data.splice(i, 1);
                        }
                        for( var j=0; j<responsData.bundle.length; j++){
                        if(searching.data[i] && (searching.data[i]._id === responsData.bundle[j]._id)){
                            searching.data.splice(i, 1);
                        }
                        }
                    }
                    setMatchedPRoducts(searching.data);
                }else{
                    setMatchedPRoducts(searching.data);
                }
                setSearchIsOpen(true);
            }else{
                setMatchedPRoducts([]);
                setSearchIsOpen(false);
            }

        }

        setSearchLoading(false);

    }

    //
    //
    const handleClick = e => {
        if(responsData.type !== 'bundle'){
            return;
        }
        if (node.current.contains(e.target)) {
            return;
        }
        setSearchIsOpen(false);
        setSearchProduct('');
    };

    //
    //
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
        document.removeEventListener("mousedown", handleClick);
        };
    });


    //
    //
    React.useEffect(()=>{
        const timeoutId = setTimeout(() => searchProduct !== '' ? _getSearch() : null, 500);
        return () => clearTimeout(timeoutId);
    },[searchProduct])
    // React.useEffect(()=>{
    //     console.log(data);
    // },[data])



    //
    //
    const _addSelectedProduct = (item) =>{
        // setSelectedPRoducts(x => [...x, item])
        console.log('_addSelectedProduct',item)
        let newBundle = [...bundle, item]
        setBundle(newBundle)
        setSearchIsOpen(false);
        setSearchProduct('');

        let weight = 0
        let price = 0
        for (let index = 0; index < newBundle.length; index++) {
            const bundleItem = newBundle[index];
            weight += bundleItem.weight ? bundleItem.weight : 0
            price += bundleItem.price
        }
        formRef.current.setFieldValue('price', price);
        formRef.current.setFieldValue('weight', weight);
    }

    //
    //
    const _removeSelectedProduct = (index) =>{
        let newSelected = bundle;
        // delete newSelected[index];
        // setSelectedPRoducts(x => newSelected.length>0 ? newSelected : [])
        // setData({...data, bundle: newSelected.length>0 ? newSelected : null})
        if(newSelected){
            // const filteredItems = newSelected.slice(index, 1)
            const filteredItems = newSelected.filter((x,i) => {
                return i !== index
            })
            setBundle(x => filteredItems)
            if(filteredItems.length){
                let weight = 0
                let price = 0
                for (let index = 0; index < filteredItems.length; index++) {
                    const bundleItem = filteredItems[index];
                    weight += bundleItem.weight ? bundleItem.weight : 0
                    price += bundleItem.price
                }
                formRef.current.setFieldValue('price', price);
                formRef.current.setFieldValue('weight', weight);
            }else{
                formRef.current.setFieldValue('price', 0);
                formRef.current.setFieldValue('weight', 0);
            }
        }else{
            setBundle(null)
            formRef.current.setFieldValue('price', 0);
            formRef.current.setFieldValue('weight', 0);
        }
    }

    //
    //
    const _searchProducts = () => {
        if(matchedProducts && matchedProducts.length>0){
        return matchedProducts.map((item,i)=>{
            return(
            <div key={i}><div><span>sku: {item.sku}</span>{item.title}</div> <a href={'#'} onClick={()=> _addSelectedProduct(item)}><Plus/> ADD</a></div>
            );
        })
        }else{
        return null;
        }
    }


    //
    //
    const _goBack = async() => {

        const allData = formRef.current.getData();
        
        let data1 = {}
        let data2 = {}

        //
        data1.title = responsData?.title
        data1.weight = responsData?.weight.toString()
        data1.qty = responsData?.qty.toString()
        data1.price = responsData?.price.toString()
        data1.description = responsData?.description
        data1.sku = responsData?.sku
        data1.status = responsData?.status
        data1.group = responsData?.group
        data1.category = responsData?.category
        data1.attributes = responsData?.attributes

        //
        data2.title = allData?.title
        data2.weight = allData?.weight
        data2.qty = allData?.qty
        data2.price = allData?.price.toString()
        data2.description = description
        data2.sku = allData?.sku
        data2.status = allData?.status._id
        data2.group = allData?.group._id

        data2.category = categories ? await _prepareForSubmitCategories(allData?.category, categories) : null

        let formAttributes = []
        const selectedAttributes = Object.entries(allData?.attributes).filter(x => x[1] !== null)
        selectedAttributes.map((item,index)=>{
             formAttributes.push({[item[0]]:item[1].value})
         })
        data2.attributes = formAttributes

        if(JSON.stringify(data1)!==JSON.stringify(data2)){
            if (!window.confirm('Are you sure you want to leave without saving?')){
                return
            }
        }
        history.goBack()

    }



    // React.useEffect(() => {
    //     const unlisten = history.block(() => {
    //       if (formRef.current?.isFormDirty()) {
    //         return 'Deseja sair sem salvar as alterações?';
    //       }
    //     });
    //     return unlisten;
    //   }, [formRef, history]);




    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>{process.env.REACT_APP_NAME}{`${responsData && responsData.title} - Products | ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                {responsData ? 
                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Products"
                        title={responsData.title}
                    >
                        <button type="button " onClick={()=> _submit()} className="button primary sm hidden md:flex"><Save size="16" className="mr-2"/> Save</button>
                        <a href="#" className="button dark sm ml-2 hidden md:flex" onClick={()=> _goBack()}><ChevronLeft size={16}/> Back</a>
                    </TopBar>

                    {responsData.status==='pending' &&
                        <div className="py-6 px-8 flex bg-yellow-500">
                            <AlertTriangle className="mr-2"/><strong>this product is in the process of approval</strong><br/>
                        </div>
                    }

                    {responsData.status==='sold' &&
                        <div className="py-6 px-8 flex bg-gray-300">
                            <ShoppingBag className="mr-2"/><strong>this product is already sold and cannot be edited or published again</strong>
                        </div>
                    }

                    {responsData.status==='rejected' &&
                        <div className="py-6 px-8 flex bg-red-500 text-white">
                            <XOctagon className="mr-2"/><strong>this product is rejected and cannot be edited or published again</strong>
                        </div>
                    }

                    {responsData.type==='bundle' &&
                        <div className="py-6 px-8 flex bg-blue-300 text-black">
                            <Info className="mr-2"/><strong>this product is looks/bundle type</strong>
                        </div>
                    }

                    <div className="_inner">
                        {/* START */}

                        <div className='flex gap-1 md:hidden mb-4'>
                            <button type="button" onClick={()=> _submit()} className="button primary sm w-full"><Save size="16" className="mr-2"/> Save</button>
                            <a href="#" className="button dark sm w-full" onClick={()=> _goBack()}><ChevronLeft size={16}/> Back</a>
                        </div>

                        {responsData.type !== 'bundle' &&
                        <div className="flex flex-col lg:flex-row gap-6 items-end">
                            <div className='w-full lg:w-auto'>
                                <BarCode id={id}/>
                            </div>
                            {responsData.ownerType==='member' && sellerData && 
                                <div className='w-full lg:w-auto'>
                                <div className="formGroup mb-0 mt-0"><label>Seller info</label></div>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                <div className="text-sm">
                                    <strong>{sellerData.member?.storeName}</strong><br/>
                                    {sellerData.member?.firstName} {sellerData.member?.lastName} ({sellerData.member?.username})<br/>
                                    {sellerData.member?.email}<br/>
                                    {sellerData.member?.phone}<br/>
                                </div>
                                <div className="text-sm">
                                    <strong>Billing address</strong><br/>
                                    {sellerData.member?.billingAddress.length>0 &&
                                        sellerData.member?.billingAddress[0].address+' '+
                                        sellerData.member?.billingAddress[0].zip+' '+
                                        sellerData.member?.billingAddress[0].city+' '+
                                        sellerData.member?.billingAddress[0].state+' '+
                                        sellerData.member?.billingAddress[0].country
                                    }
                                </div>
                                <div className="text-sm">
                                    <strong>Shipping address</strong><br/>
                                    {sellerData.member?.sameAddress ? 
                                    <>
                                    {sellerData.member?.billingAddress.length>0 &&
                                        sellerData.member?.billingAddress[0].address+' '+
                                        sellerData.member?.billingAddress[0].zip+' '+
                                        sellerData.member?.billingAddress[0].city+' '+
                                        sellerData.member?.billingAddress[0].state+' '+
                                        sellerData.member?.billingAddress[0].country
                                    }
                                    </>
                                    :
                                    <>
                                    {sellerData.member?.shippingAddress.length>0 &&
                                        sellerData.member?.shippingAddress[0].address+' '+
                                        sellerData.member?.shippingAddress[0].zip+' '+
                                        sellerData.member?.shippingAddress[0].city+' '+
                                        sellerData.member?.shippingAddress[0].state+' '+
                                        sellerData.member?.shippingAddress[0].country
                                    }
                                    </>
                                    }
                                </div>
                                </div>
                                </div>
                            }        
                        </div>
                        }

                        <Form ref={formRef} onSubmit={handleSubmit} className="w-full">

                            {responsData.type==='bundle' &&
                                <>
                                <div className="box p-6 my-6">
                                    <div className="flex justify-between items-center">
                                        <h4 className="mb-2 font-bold">Bundled products</h4>
                                        {searchLoading && <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div>}
                                    </div>
                                <div className="searchProductDDwraper">
                                    <div className="formGroup">
                                        <input 
                                            type="text" 
                                            className="formControl" 
                                            value={searchProduct}
                                            onChange={e => { setSearchProduct(e.target.value) }}
                                            placeholder="Type to search products..."
                                            autoComplete="off"
                                        />
                                    </div>
                                
                                    <div className={searchIsOpen ? 'searchProductDD transition active' : 'searchProductDD transition'} ref={node}>{_searchProducts()}</div>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                {bundle && bundle.length>0 && bundle.map((item,i)=>{
                                    if(item){
                                    return (
                                        <div key={i}>
                                        {item.featuredImage != '' && <img src={process.env.REACT_APP_UPLOAD_URL+'/'+item.featuredImage+'_400.jpg'} alt=""/>}
                                        <span className="my-2 text-xs">sku: {item.sku}</span>
                                        <h4 className="text-sm font-bold">{item.title}</h4>
                                        <a href={'#'} onClick={()=> _removeSelectedProduct(i)} className="text-xs uppercase text-red-500 mt-2">remove</a>
                                        </div>
                                    )
                                    }
                                })}
                                </div>
                                </div>
                                </>
                            }

                            <div className="box p-6 my-6">
                                <Input name="title" placeholder="title..."/>
                                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
                                    <SingleSelect name="status" data={statuses} label="Status"/>
                                    <SingleSelect name="group" data={groups} label="Group"/>
                                    <Input name="sku" label="SKU"/>
                                    <Input name="price" label="Price" readOnly={responsData.type==='bundle' ? true : false}/>
                                    <div className="grid grid-cols-2 gap-6">
                                        <Input name="qty" label="QTY" type="number"/>
                                        <Input name="weight" label="Weight" readOnly={responsData.type==='bundle' ? true : false}/>
                                    </div>
                                    
                                </div>
                                <hr className="my-6"/>
                                <div className="flex flex-col lg:flex-row w-full gap-4">
                                    <div className="w-full lg:w-3/5">
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6">
                                        {
                                            attributes && attributes.map((item,index)=>{
                                                return _renderAttributes(item,index)
                                            })
                                        }
                                        </div>
                                        <div className="formGroup mt-4">
                                            <div className="switch mt-2">
                                                <input type="checkbox" name="type" value="" checked={featuredInCloset} onChange={()=> setFeaturedInCloset(!featuredInCloset)}/>
                                                <div className="mr-2"><i></i></div>
                                                <label>Featured in closet - {featuredInCloset ? 'Yes' : 'No'}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-2/5">
                                        <div className="formGroup ml-2">
                                            <label>Categories</label>
                                            <div className="h-96 overflow-y-auto p-4 border-[1px] border-gray-200">
                                            {categories && _rendercategories(categories)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col lg:flex-row w-full gap-6">
                                <div className="w-full lg:w-2/3">

                                    <div className="formGroup">
                                        <label>Description</label>
                                        <ContentEditorBasic
                                            value={description}
                                            initialValue={description}
                                            onEditorChange={_handleEditorChange}
                                        />
                                    </div>

                                    <div className="box p-6 my-6">
                                        <div className="flex justify-between items-center">
                                            <h4 className="mb-2 font-bold">Gallery</h4>
                                            <button type="button" className="button dark sm" onClick={() => { _mediaOpen(0, 'gallery') }}>Add images</button>
                                        </div>
                                        <hr className="my-4"/>
                                        <div className="productGallery">
                                        <Nestable
                                            items={gallery}
                                            renderItem={renderGalleryItem}
                                            maxDepth={0}
                                            group="gallery"
                                            idProp="_id"
                                            onChange={(event)=>{ setGallery(event.items); }}
                                        />
                                        </div>
                                    </div>

                                </div>
                                <div className="w-full lg:w-1/3 mt-3">

                                    <div className="box p-6 my-6">
                                        <h4 className="mb-2 font-bold">Featured Image</h4>
                                        {featuredImage != '' && <img src={process.env.REACT_APP_UPLOAD_URL+'/'+featuredImage+'_400.jpg'} alt=""/>}
                                        <button type="button" className="button secondary sm mt-2 w-full" onClick={() => { _mediaOpen(0, 'featured') }}>{featuredImage === '' ? 'Select image' : 'Change image'}</button>
                                    </div>

                                    <div className="box p-6 my-6">
                                        <h4 className="mb-2 font-bold">SEO</h4>
                                        <Input name="seoTitle" label="Title"/>
                                        <TextArea name="seoKeywords" label="Keywords"/>
                                        <TextArea name="seoDescription" label="Description"/>
                                    </div>

                                </div>
                            </div>

                        </Form>

                        {/* END */}
                    </div>
                </div>
                : null
                }
            </Layout>


            {mediaState && <Media
                state={mediaState}
                type={mediaType}
                index={mediaIndex}
                close={()=> _mediaClose()}
                action={_insertMedia}
              />
            }

        </Fragment>
    );

}
