import React from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { Form } from '@unform/web'
import * as Yup from 'yup';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';


// components
import Layout from '../components/Layout'
import Loading from '../components/Loading'
import Input from '../components/Forms/Input'


// controllers
import { _apiPOST } from '../controllers/Api';
import { _toaster, _log, _timeout } from '../controllers/Helpers'
import _actions from '../controllers/redux/actions'


export default function Page404(props){

    const formRef = React.useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const [ isLoading, setLoading ] = React.useState(false)


    //
    //
    const handleSubmit = React.useCallback(async(data) => {
        try {
    
            // Remove all previous errors
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                username: Yup.string().required(),
                password: Yup.string().required()
            });
            await schema.validate(data, {
                abortEarly: false,
            });
        
            setLoading(true);

            const login = await _apiPOST('/auth/', {...data} ,false)
            if(login.error){
                _toaster(login.error.status, login.error.message, 'error')
                setLoading(false);
            }
            if(login.user){
                if(login.user.data.role>3){
                    _toaster('403 | Access denied', 'You dont have permission to access requested page.', 'error')
                    return
                }
                const state = dispatch(_actions.auth.authUpdate({...login.user.data, token:login.user.token}))
                if(state){
                    _log('state',state)
                    await _timeout(500)
                    history.push('/dashboard/')
                }
                setLoading(false);
            }
    
        } catch (err) {
    
          const validationErrors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
              validationErrors[error.path] = error.message;
            });
            formRef.current.setErrors(validationErrors);
          }
    
        }
      }, [dispatch,history]);


    //
    //
    React.useEffect(()=>{
        dispatch(_actions.auth.authLogout())
    },[dispatch])


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Login | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main login">
                <div className="container w-full max-w-2xl flex flex-col md:flex-row justify-between items-center px-4">
                    <div className="box mx-auto p-8 w-full md:w-2/5 md:-mr-2 z-20">
                        <h2 className="text-sm mb-5">LOGIN TO YOUR ACCOUNT</h2>
                        <Form ref={formRef} onSubmit={handleSubmit} className="w-full">
                            <Input type="email" name="username" placeholder="Email"/>
                            <Input type="password" name="password" placeholder="Password"/>
                            <button type="submit" className="button dark w-full">Login</button>
                        </Form>
                    </div>
                    <div className="p-10 bg-white bg-opacity-30 backdrop-blur-sm w-full md:w-3/5 z-10 hidden md:block">
                        <p className="text-xl">Hello,</p>
                        <p className="font-bold text-4xl">Welcome Back</p>
                        <p className="text-xl">{process.env.REACT_APP_NAME}</p>
                    </div>
                </div>
            </Layout>

            <Loading status={isLoading}/>

        </Fragment>
    );

}
