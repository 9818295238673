import React from 'react';
import {toast} from 'react-toastify'


//
//
export const _log = (title, message, type='log') => {
  if(process.env.REACT_APP_DEBUG==='true'){
    console[type](title, message)
  }
}


//
//
export const _timeout = function(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  });
}


//
//
export const _toaster = (title=null, message=null, type) => {

  toast[type](
    <>
        {title && <strong className="mb-2 block text-sm">{title}</strong>}
        {message && <p className="text-sm text-white text-opacity-75">{message}</p>}
    </>
  )

}


//
//
export const _checkRole = (roles, current) => {

  if(!roles){
    return true
  }

  const available = roles.filter(x => current===x);
  if(available.length>0){
    return true
  }

  return false
}


//
//
export const _roleName = (role) => {

  const roles = [
    {id:1, name:'Super administrator'},
    {id:2, name:'Administrator'},
    {id:3, name:'Moderator'},
    {id:10, name:'Owner'},
    {id:11, name:'Employeer'}
  ]

  const index = roles.findIndex(x => x.id===role);
  if(index !== -1){
    return roles[index].name
  }else{
    return 'N/A'
  }

}



//
//
export const _labelByValue = (value, list) => {
  const index = list.findIndex(x => x.value===value);
  if(index !== -1){
    return list[index].label
  }
}


//
//
export const _itemByValue = (value, list, field='value') => {
  const index = list.findIndex(x => x[field]===value);
  if(index !== -1){
    return list[index]
  }
}


//
//
export const _goBack = (location) => {
  if(location.state && location.state !== ''){
    return {pathname:location.state.pathname, state: location.state}
  }else{
    return false;
  }
}


//
// user/member initials for avatar
export const _initials = (user) => {

  let initials = '';
  if (user.firstName) {
    if (user.firstName.length > 1) {
      initials = user.firstName.charAt(0);
    }
  }
  if (user.lastName) {
    if (user.lastName.length > 1) {
      initials = (initials.length > 0 ? initials + ' ' : null) + user.lastName.charAt(0);
    }
  }
  return initials;

}



//
//
export const inArray = (needle, haystack) => {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
}



//
//
export const _isActivePage = (path, location) => {
  const index  = (location.pathname).indexOf(path)
  if(index===0){
    return true
  }else{
    return false
  }
}



//
//
export const _productStatus = (data) => {
  if(data==='active'){
    return (<span className="statusBadge active">Available</span>)
  }else if(data==='rejected'){
    return <span className="statusBadge declined">Rejected</span>
  }else if(data==='sold'){
    return <span className="statusBadge sold">Sold</span>
  }else if(data==='removed'){
    return <span className="statusBadge removed">Removed</span>
  }else if(data==='pending'){
    return <span className="statusBadge pending">In review</span>
  }else if(data==='reserved'){
    return <span className="statusBadge reserved">Reserved</span>
  }else{
    return null;
  }
}


//
//
export const _status = (data, cssClass=null) => {
  if(data==='confirmed'){
    return <span className="bg-green-500 rounded-sm text-xxs uppercase px-2 py-1 text-white">Confirmed</span>
  }else if(data==='approved'){
    return <span className="bg-green-500 rounded-sm text-xxs uppercase px-2 py-1 text-white">Approved</span>
  }else if(data==='active'){
    return <span className="bg-green-500 rounded-sm text-xxs uppercase px-2 py-1 text-white">Active</span>
  }else if(data==='failed'){
    return <span className="bg-red-500 rounded-sm text-xxs uppercase px-2 py-1 text-white">Failed</span>
  }else if(data==='rejected'){
    return <span className="bg-red-500 rounded-sm text-xxs uppercase px-2 py-1 text-white">Rejected</span>
  }else if(data==='canceled'){
    return <span className="bg-red-500 rounded-sm text-xxs uppercase px-2 py-1 text-white">Canceled</span>
  }else if(data==='pending'){
    return <span className="bg-yellow-500 rounded-sm text-xxs uppercase px-2 py-1 text-black">Pending</span>
  }else if(data==='shipped'){
    return <span className="bg-gray-500 rounded-sm text-xxs uppercase px-2 py-1 text-white">Shipped</span>
  }else if(data==='reserved'){
    return <span className="bg-gray-500 rounded-sm text-xxs uppercase px-2 py-1 text-white">Reserved</span>
  }else if(data==='partially shipped'){
    return <span className="bg-gray-800 rounded-sm text-xxs uppercase px-2 py-1 text-white">partially Shipped</span>
  }else if(data==='completed'){
    return <span className="bg-secondary rounded-sm text-xxs uppercase px-2 py-1 text-black">Completed</span>
  }else if(data==='sold'){
    return <span className="bg-secondary rounded-sm text-xxs uppercase px-2 py-1 text-black">Sold</span>
  }else{
    return null;
  }
}



//
//
export const _copy = (text, notification=true) => {

  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if(notification){
    _toaster('Copied', false, 'success')
  }
}



//
//
export const AlwaysScrollToBottom = () => {
  const elementRef = React.useRef();
  React.useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};



//
//
export const _sortBy = (field, reverse, primer) => {

  const key = primer ?
    function(x) {
      return primer(x[field])
    } :
    function(x) {
      return x[field]
    };

  reverse = !reverse ? 1 : -1;

  return function(a, b) {
    return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
  }
}