import React, { useEffect, useState, Fragment, useCallback } from 'react';
import Modal from 'react-modal';
import './style.scss';
import { X, Search, Loader, Circle, CheckCircle } from 'react-feather';
import { toast } from 'react-toastify'
import {useDropzone} from 'react-dropzone'
import { HexColorPicker, HexColorInput } from "react-colorful";

// variables
import Loading from '../../components/Loading';
import ProgressiveImageWrapper from '../../components/ProgressiveImageWrapper'
import InlineEdit from '../../components/InlineEdit';

// controllers
import { API } from '../../controllers/Api';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,.4)';
const customStyles = {
  content : {
    display:'flex',
    flexDirection:'column',
    boxShadow: '0px 2px 15px rgba(0,0,0,.15)',
    zIndex:9999999,
    width: '90%',
    height: '90%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none'
  },

};


export default function Media(props) {

  const [loading, setLoading] = useState(true);
  const pickStyle = 'single';
  const [data, setData] = useState([]);

  const limit = 20;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const [slazzerApi, setSlazzerApi] = useState(props.slazzer ? true : false);
  const [slazzerBg, setSlazzerBg] = useState(process.env.REACT_APP_COLORBG);

  const [selected, setSelected] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [searchSpinner, setSearchSpinner] = useState(true);

  const node = React.useRef(null);
  const [ isPickerOpen, setIsPickerOpen ] = useState(false);


  //
  //
  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setIsPickerOpen(false);
  };


  //
    //
    React.useEffect(() => {
      slazzerApi && document.addEventListener("mousedown", handleClick);
      return () => {
        slazzerApi && document.removeEventListener("mousedown", handleClick);
      };
  });


  //
  //
  const onDrop = useCallback((acceptedFiles) => {

    setLoading(true);

    let formData = new FormData();
    formData.append('slazzer', slazzerApi);
    formData.append('slazzerBg', slazzerBg);
    for (var i = 0; i < acceptedFiles.length; i++) {
      formData.append('files[]', acceptedFiles[i]);
      formData.append('names[]', acceptedFiles[i].name);
    }

    API(true, {})
    .post('media/insert/', formData)
    .then(response => {
      console.log(response.data.images);
      setLoading(false);
      setData(data => [...response.data.images, ...data]);

    }).catch(error => {
      setLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });

  }, [slazzerApi, slazzerBg]);
  const {getRootProps, getInputProps} = useDropzone({onDrop});


  //
  //
  const _checkSelected = (image) => {
    let check = selected.find(x => x._id === image._id);
    if(check){
      return true;
    }else{
      return false;
    }
  }


  //
  //
  const _select = (image) => {
    if(props.type==='gallery'){

      let check = selected.find(x => x._id === image._id);
      if(check){
        setSelected(selected => selected.filter(id => id._id !== image._id));
      }else{
        setSelected([...selected, image]);
      }

    }else{

      if(selected.length>0 && selected[0]._id===image._id){
        setSelected([]);
      }else{
        setSelected([image]);
      }

    }
  }


  //
  //
  const _get = (q=null) => {

    let offset = 0;
    if(page>0){
      offset = (page*limit);
    }

    setSearchSpinner(true);
    setLoading(true);
    API(true, {
      'X-MetaData': JSON.stringify({
        search: q ? q : '',
        limit: limit,
        offset: offset
      })
    })
    .get('media/query/')
    .then(response => {

      setTotal(response.data.total);
      if(response.data.total !== 0){
        if(offset !== 0){
          if(q){
            setData(response.data.data)
          }else{
            setData([...data,...response.data.data])
          }
        }else{
          setData(response.data.data);
        }
      }else{
        setData([]);
      }
      setSearchSpinner(false);
      setLoading(false);

    }).catch(error => {
      setSearchSpinner(false);
      setLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });

  }


  //
  //
  const _loadMore = () =>{
    setPage(page => page+1);
  }


  //
  //
  const _onOpen = () => {
    setSlazzerApi(props.slazzer ? true : false);
    setSlazzerBg(process.env.REACT_APP_COLORBG);
    setSelected([]);
    setData([]);
    setPage(0);
    _get();
  }


  //
  //
  const _onClose = () => {
    setSelected([]);
    setPage(0);
    setData([]);
    props.close();
  }


  //
  //
  const _deleteImage = (image) => {
    setLoading(true);

    API(true, {})
    .delete('media/delete/'+image._id)
    .then(response => {
      setLoading(false);
      setData(data => data.filter(id => id._id !== image._id));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(error => {
      setLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });

  }


  //
  //
  const _updateImage = (image, value) => {

    let item = data.find(x => x._id === image._id);
    if(item.alt === value){ return false; }
    API(true, {})
    .put('media/update/'+image._id,  {alt: value})
    .then(response => {
      item.alt = value;
      setData(newData => ([...data]));
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch(error => {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });

  }


  //
  //
  useEffect(()=>{

    if(props.state){
      setPage(0);
      _get(searchValue);
    }

  },[searchValue]);


  //
  //
  useEffect(()=>{

    if(props.state){
      if(searchValue && searchValue !== ''){
        _get(searchValue);
      }else{
        _get();
      }
    }

  },[page]);


  //
  //
  const _handleColorChange = (value) => {
    setSlazzerBg(value)
  }


  //
  //
  return (
    <Fragment>
      <Modal
        isOpen={props.state}
        onAfterOpen={_onOpen}
        onRequestClose={_onClose}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="modalHead">
          <h3>Medias</h3>
          <button type="button" onClick={_onClose}><X/></button>
        </div>
        <div className="modalToolbar p-3">
          {pickStyle==='single' &&
            <div className="row no-gutters">
              <div className="col-4 d-flex align-items-end">
                <div className="modalSearch">
                  {searchSpinner ? <Loader className="spin"/> : <Search/>}
                  <input type="text" value={searchValue} onChange={(e)=> { setSearchValue(e.target.value)}} placeholder="Search medias..."/>
                </div>
              </div>
              <div className="col-8 d-flex justify-content-end">
                <div className="formGroup mr-2">
                  <div className="switch mt-2">
                    <input type="checkbox" name="type" value="" checked={slazzerApi} onChange={()=> setSlazzerApi(!slazzerApi)}/>
                    <div className="mr-2"><i></i></div>
                    <label>Enable Slazzer Api</label>
                  </div>
                </div>
                {slazzerApi &&
                  <div className={`ddColorPicker ${isPickerOpen ? 'active' : ''}`} ref={node}>
                    <button type="button p-4" className="button" style={{backgroundColor:slazzerBg}} onClick={()=> setIsPickerOpen(!isPickerOpen)}></button>
                    <div>
                      <HexColorPicker color={slazzerBg} onChange={_handleColorChange} alpha="true" prefixed="true"/>
                      <HexColorInput color={slazzerBg} onChange={_handleColorChange} />
                      <button type="button" className="button dark sm w-full mt-2" onClick={()=>setIsPickerOpen(false)}>Close</button>
                    </div>
                  </div>
                }
                <div className="uploadBtn mr-1" {...getRootProps()}>
                  <span>Select file for Upload</span>
                  <input {...getInputProps()}/>
                </div>
                {props.gallery && <button type="button" className="button mr-1" onClick={() => {}}>Create gallery</button>}
                {selected.length>0  && <button type="button" className="button dark mr-1" onClick={() => { props.action(props.index, selected) }}>Insert selected</button>}
              </div>
            </div>
          }
        </div>
        <div className="modalContent d-flex">
          {
            data.length>0 ? data.map((image,i)=>{
              return (
                <div key={i} className={_checkSelected(image) ? 'modalItem selected' : 'modalItem'}>
                  {_checkSelected(image) && <div className="selectedBadge">selected</div>}
                  <ProgressiveImageWrapper
                    source={process.env.REACT_APP_UPLOAD_URL+'/'+image.fileName+'_400.'+image.extension}
                    thumb={process.env.REACT_APP_UPLOAD_URL+'/'+image.fileName+'_40.'+image.extension}
                    alt={image.alt}
                    style={{width:'100%', height:125}}
                  />
                  <div className="bottom">
                    <InlineEdit text={image.alt} onSave={(val)=> _updateImage(image,val) }/>
                    <div className="row no-gutters d-flex align-items-center">
                      <div className="col-6">
                        <div className="formGroup mt-0 mb-0">
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              className={`formControl`}
                              checked={_checkSelected(image)}
                              onChange={(event) => { _select(image) }}
                            />
                            <div>
                              <CheckCircle className="checked" color="#94c943"/>
                              <Circle className="unchecked" color="#999"/>
                            </div>
                          </div>
                        </div>
                        {/* <FormControlLabel
                          control={<Checkbox icon={<Square/>} checkedIcon={<CheckSquare className="text-accent"/>} name="slazzer" />}
                          label={''}
                          checked={_checkSelected(image)}
                          onChange={(event) => { _select(image) }}
                          color="primary"
                        /> */}
                      </div>
                      <div className="col-6 d-flex justify-content-end">
                        <div className="p-1 pr-0 delete" onClick={()=> { _deleteImage(image) }}><X/></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
            :
            <div className="noPosts">{searchValue==='' ? 'There\'s no data by current search criteria.' : 'There\'s no media.'}</div>
          }
        </div>
        {total>((page+1)*limit) && <a href="#" className="loadMore" onClick={()=>_loadMore()}>Load more</a>}
        <Loading type="full" text="loading..." status={loading}/>
      </Modal>
    </Fragment>
  );

};
