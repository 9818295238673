import React, { Fragment } from 'react';
// import { Power, Menu } from 'react-feather'
import dayjs from 'dayjs';


//
//
export default function Table(props) {


  //
  //
  const _parseField = (data, field, index) => {

    if(field.type==='options'){
      return  '-'
    }else if(field.type==='function'){
      return field.func(data, index)
    }else if(field.type==='date'){
      return dayjs(data[field.key]).format(field.format ? field.format : 'DD.MM.YYYY HH:mm')
    }else{
      return data[field.key]
    }

  }


  //
  //
  const _tdClass = (isLast, field, cssClass=null) => {

    let _class = []
    const sort = props.sortBy.toString().split('|')

    _class.push(isLast ? 'right' : 'left')
    if(sort[0]===field.key){
      _class.push(sort[0]===field.key ? 'active' : '')
      _class.push(sort[1]==='asc' ? 'down' : 'up')
    }
    if(field.sortable){
      _class.push('clickable')
    }

    return _class.join(' ')+(cssClass ? ' '+cssClass : '')

  }


  //
  //
  const _sort = (field) => {

    const sort = props.sortBy.toString().split('|')
    if(sort[0]===field.key){
      props.action('orderBy', field.key+'|'+(sort[1]==='asc' ? 'desc' : 'asc'))
    }else{
      props.action('orderBy', field.key+'|asc')
    }

  }


  //
  //
  return (
    <Fragment>
      <div className="tableWrapper">

        <div className="head">
          <table cellPadding="0" cellSpacing="0">
          <thead>
          {
            props.head && props.head.length>0 &&
            <tr>
            {
              props.head.map((item,i)=>{
                return (
                  <th
                    width={item.width && item.width}
                    key={i}
                    className={_tdClass(props.head.length===(i+1), item, item.class)}
                    onClick={()=> item.sortable ? _sort(item) : null}
                  >
                  {item.name}
                  </th>
                )
              })
            }
            </tr>
          }
          </thead>
          </table>
        </div>

        <div className="body">
          <table cellPadding="0" cellSpacing="0">
          <tbody>
          {
            props.body && props.body.length>0 && props.head.length>0 &&
            props.body.map((data,i1)=>{
              return (
                <tr key={i1}>
                {
                  props.head.map((item,i2)=>{
                    return (
                      <td
                        width={item.width && item.width}
                        key={i2}
                        className={_tdClass(props.head.length===(i2+1), item, item.class)}
                      >
                      {_parseField(data, item, i1)}
                      </td>
                    )
                  })
                }
                </tr>
              )
            })
          }
          </tbody>
          </table>
        </div>

      </div>
    </Fragment>
  );
};
