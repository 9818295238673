import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs'
import { ChevronLeft } from 'react-feather';



// components
import Layout from '../../../components/Layout'
import Loading from '../../../components/Loading'
import Sidebar from '../../../components/Sidebar'
import TopBar from '../../../components/TopBar'


// controllers
import { _apiGETone, _apiPOST } from '../../../controllers/Api';
import { _status, _toaster } from '../../../controllers/Helpers'


function ProductLink(props){

    const [ productData, setProductData ] = React.useState(null)

    //
    //
    const _query = async() => {
        const get = await _apiGETone('/shop/products/single/'+props.id)
        if(!get.error){
            setProductData(get.data);
        }
    }


    //
    //
    React.useEffect(()=>{
        _query();
    },[]);


    return productData ? <> <span className="px-2">|</span> <a href={process.env.REACT_APP_WEB_URL+'/product/'+productData.permalink} target="_blank" rel="norefferer" className="font-bold text-primary">view</a></> : null

}


//
//
export default function PageOrderDetails(props){

    const auth  = useSelector(state => state.auth);
    const history = useHistory()
    const { id } = useParams();

    const [ isLoading, setLoading ] = React.useState(true);
    const [ responsData, setResponseData ] = React.useState([]);
    const [ sellersIncluded, setSellersIncluded ] = React.useState([]);


    //
    //
    const _query = async() => {

        setLoading(true)

        const get = await _apiGETone('/transactions/order/'+id)
        if(!get.error){
            setResponseData(get);
            if(get.products){
                const groupped = get.products.reduce(function (r, a) {
                    r[a.ownerId] = r[a.ownerId] || [];
                    r[a.ownerId].push(a);
                    return r;
                }, Object.create(null));
                setSellersIncluded(groupped)
            }
        }

        setLoading(false)
    }


    //
    //
    const _totalWeight = () => {

        let totalWeight = 0
        if(responsData.products){
            for (let index = 0; index < responsData.products.length; index++) {
                const product = responsData.products[index];
                totalWeight += product.weight
            }
        }

        return totalWeight.toFixed(3)
    }


    //
    //
    const _delete = async(item) => {

        if (!window.confirm('Are you sure you want to delete this item?')){
            return
        }

        setLoading(true)
        const deleteItem = await _apiPOST('/transactions/delete/'+id, {item:item})
        if(!deleteItem.error){
            _toaster('Success', deleteItem.message, 'success')
            _query();
        }
        setLoading(false)

    }


    //
    //
    const _refund = async(item) => {

        if (!window.confirm('Are you sure you want to refund this item?')){
            return
        }

        setLoading(true)
        const refundItem = await _apiPOST('/transactions/refund/'+id, {item:item})
        if(!refundItem.error){
            _toaster('Success', refundItem.message, 'success')
            _query();
        }
        setLoading(false)

    }


    //
    //
    const _cancel = async() => {

        if (!window.confirm('Are you sure you want to cancel order?')){
            return
        }

        setLoading(true)
        const cancelOrder = await _apiPOST('/transactions/cancel/'+id, responsData)
        if(!cancelOrder.error){
            _toaster('Success', cancelOrder.message, 'success')
        }
        setLoading(false)

    }


    // 
    // 
    const _downloadInvoice = async(seller) => {
        const download = await _apiPOST('/transactions/pdf/'+id+'/'+seller, responsData)
        if(!download.error){
            const link = document.createElement('a');
            link.href = download;
            link.setAttribute('download', 'order_'+id+'_'+seller+'.pdf');
            document.body.appendChild(link);
            link.click();
        }
    }


    //
    //
    React.useEffect(()=>{
        _query();
    },[]);


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>{`Order #${!isLoading && responsData.orderNum} - Transactions | ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />
                
                {!isLoading ? 
                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Transactions / Orders"
                        title={'Order #'+responsData.orderNum}
                    >
                        {responsData.status==='confirmed' && <button type="button" onClick={()=> _cancel()} className="button red sm hidden md:flex">Cancel order</button>}
                        <a href="#" className="button dark sm ml-2 hidden md:flex" onClick={()=> history.goBack()}><ChevronLeft/> Back</a>
                    </TopBar>
                    <div className="_inner">
                        {/* START */}

                        <div className='flex gap-1 md:hidden mb-4'>
                        {responsData.status==='confirmed' && <button type="button" onClick={()=> _cancel()} className="button red sm w-full">Cancel order</button>}
                            <a href="#" className="button dark sm w-full" onClick={()=> history.goBack()}><ChevronLeft/> Back</a>
                        </div>

                        <div className="box p-6 my-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                <div>
                                    <small>Order no.</small>
                                    <h4 className="font-bold text-2xl">{responsData.orderNum}</h4>
                                </div>
                                <div>
                                    <small>Status</small>
                                    <h4>{_status(responsData.status)}</h4>
                                </div>
                                <div>
                                    <small>Created</small>
                                    <h4>{dayjs(responsData.dateCreated).format('DD.MM.YYYY HH:mm')}</h4>
                                </div>
                                <div>
                                    <small>Updated</small>
                                    <h4>{dayjs(responsData.dateUpdated).format('DD.MM.YYYY HH:mm')}</h4>
                                </div>
                            </div>
                        </div>

                        <div className="my-4">
                            <div className="flex flex-row items-center border-b-2 border-black">
                                <div className="w-full md:w-2/6 py-2">Product</div>
                                <div className="hidden md:flex w-1/6 py-2">Shipping company</div>
                                <div className="hidden md:flex w-1/6 py-2">Shipping number</div>
                                <div className="hidden md:flex w-1/6 py-2">Seller</div>
                                <div className="hidden md:flex w-1/6 py-2 md:justify-end text-right">Price</div>
                            </div>
                            {
                                responsData.products?.map((item,i)=>{
                                    return(
                                        <div className={`flex flex-col md:flex-row items-center border-b-[1px] border-gray-300`} key={i}>
                                            <div className="w-full md:w-2/6 py-2">
                                                <div>
                                                <span className={`${item.isRefund ? 'line-through opacity-50' : ''}`}>
                                                    {item.title}
                                                </span>
                                                {item.isRefund && <span className="ml-2">REFUNDED</span>}
                                                </div>
                                                <span className={`block text-sm ${item.isRefund ? 'opacity-50' : ''}`}>
                                                    sku: {item.sku}
                                                    <ProductLink id={item._id}/>
                                                    {!item.isRefund && responsData.status==='completed' && <span className="px-2">|</span>}
                                                    {!item.isRefund && responsData.status==='completed' && <button className="text-sm text-red-500 font-bold" onClick={()=>_refund(item)}>refund</button>}
                                                    {!item.isRefund && responsData.status !== 'completed' && <><span className="px-2">|</span><button className="text-sm text-red-500 font-bold" onClick={()=>_delete(item)}>delete</button></>}
                                                </span>
                                            </div>
                                            <div className={`w-full md:w-1/6 py-2 text-sm ${item.isRefund ? 'opacity-50' : ''}`}>{item.shippingName}</div>
                                            <div className={`w-full md:w-1/6 py-2 text-xs ${item.isRefund ? 'opacity-50' : ''}`}>
                                                {item.tracking ? 
                                                    <>
                                                    <span className="text-sm">{item.tracking}</span>
                                                    </>
                                                    :
                                                    <>N/A</>
                                                }
                                            </div>
                                            <div className={`w-full md:w-1/6 py-2 text-sm ${item.isRefund ? 'opacity-50' : ''}`}>{item.seller.storeName}</div>
                                            <div className={`w-full md:w-1/6 py-2 text-right ${item.isRefund ? 'line-through opacity-50' : ''}`}>{process.env.REACT_APP_CURRENCY+parseFloat(item.price).toFixed(2)}</div>
                                        </div>
                                    )
                                })
                            }
                            {
                                responsData.donations?.map((item,index)=>{
                                return(
                                    <div className="flex flex-row items-center border-b-[1px] border-gray-300" key={index}>
                                        <div className="w-full md:w-4/5 py-2">{item.name}<small className="pl-1"> - donation</small></div>
                                        <div className="w-full md:w-1/5 py-2 text-right">{process.env.REACT_APP_CURRENCY+parseFloat(item.amount).toFixed(2)}</div>
                                    </div>
                                )
                                })
                            }
                            {responsData.totals.discount > 0 && 
                                <div className="flex flex-row items-center justify-end border-b-[1px] border-gray-300">
                                    <div className="w-full md:w-1/4 py-2 font-bold flex justify-end">Coupon discount: <div className="w-24 text-right">{process.env.REACT_APP_CURRENCY+'-'+parseFloat(responsData.totals.discount).toFixed(2)}</div></div>
                                </div>
                            }
                            <div className="flex flex-row items-center justify-end border-b-[1px] border-gray-300">
                                <div className="w-full md:w-1/4 py-2 font-bold flex justify-end">Facilitation fee: <div className="w-24 text-right">{process.env.REACT_APP_CURRENCY+parseFloat(responsData.totals.buyersFee).toFixed(2)}</div></div>
                            </div>
                            <div className="flex flex-row items-center justify-end border-b-[1px] border-gray-300">
                                <div className="w-full md:w-1/4 py-2 font-bold flex justify-end">Shipping: <div className="w-24 text-right">{process.env.REACT_APP_CURRENCY+parseFloat(responsData.totals.shippingTotal).toFixed(2)}</div></div>
                            </div>
                            <div className="flex flex-row items-center justify-end">
                                <div className="w-full md:w-1/4 py-2 font-bold text-xl flex justify-end">Total: <div className="w-24 text-right">{process.env.REACT_APP_CURRENCY+parseFloat(responsData.totals.total).toFixed(2)}</div></div>
                            </div>
                            <div className="flex flex-row items-center justify-end">
                                <div className="w-full md:w-1/4 py-2 font-bold text-sm flex justify-end">weight: <div className="w-24 text-right">{_totalWeight()}</div></div>
                            </div>

                            {
                                responsData.paymentFromBalance && 
                                <>
                                    <hr className='my-5'/>
                                    <div className="flex flex-row items-center justify-end">
                                        <div className="w-full md:w-1/4 py-2 font-bold text-sm flex justify-end">from balance: <div className="w-24 text-right">{process.env.REACT_APP_CURRENCY+parseFloat(responsData.paymentFromBalance).toFixed(2)}</div></div>
                                    </div>
                                    {responsData.paymentFromBalance < parseFloat(responsData.totals.total) && 
                                        <div className="flex flex-row items-center justify-end">
                                            <div className="w-full md:w-1/4 py-2 font-bold text-sm flex justify-end">from stripe: <div className="w-24 text-right">{process.env.REACT_APP_CURRENCY+(parseFloat(responsData.totals.total)-parseFloat(responsData.paymentFromBalance)).toFixed(2)}</div></div>
                                        </div>
                                    }
                                </>
                            }
                        </div>


                        <div className="box p-6 my-4">
                            <h4 className="font-bold text-xl mb-6">Invoices</h4>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                                {
                                    Object.keys(sellersIncluded).length>0 && Object.keys(sellersIncluded).map((id, index)=>{
                                        console.log(sellersIncluded[id])
                                        return(
                                            <div className='flex flex-col md:flex-row justify-between md:items-center' key={index}>
                                                <h4>{sellersIncluded[id][0].seller.storeName}</h4>
                                                <button 
                                                    onClick={()=>_downloadInvoice(id)}
                                                    className="button secondary sm"
                                                >Download PDF</button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>


                        <div className="box p-6 my-4">
                            <h4 className="font-bold text-xl mb-6">Billing information</h4>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                <div>
                                    <small>First name</small>
                                    <h4>{responsData.userData.firstName}</h4>
                                </div>
                                <div>
                                    <small>Last name</small>
                                    <h4>{responsData.userData.lastName}</h4>
                                </div>
                                <div>
                                    <small>Email</small>
                                    <h4>{responsData.userData.email}</h4>
                                </div>
                                <div>
                                    <small>Phone</small>
                                    <h4>{responsData.userData.phone}</h4>
                                </div>
                                <div>
                                    <small>Address</small>
                                    <h4>{responsData.userData.billingAddress.address}</h4>
                                </div>
                                <div>
                                    <small>City / Zip</small>
                                    <h4>{responsData.userData.billingAddress.city}, {responsData.userData.billingAddress.zip}</h4>
                                </div>
                                <div>
                                    <small>Country</small>
                                    <h4>{responsData.userData.billingAddress.country}</h4>
                                </div>
                            </div>
                        </div>

                        <div className="box p-6 my-4">
                            
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                                <div>
                                    <h4 className="font-bold text-xl">Shipped to</h4>
                                </div>
                                <div>
                                    <small>Address</small>
                                    <h4>{responsData.userData.sameAddress ? responsData.userData.billingAddress.address : responsData.userData.shippingAddress.address}</h4>
                                </div>
                                <div>
                                    <small>City / Zip</small>
                                    <h4>{responsData.userData.sameAddress ? responsData.userData.billingAddress.city : responsData.userData.shippingAddress.city}, {responsData.userData.sameAddress ? responsData.userData.billingAddress.zip : responsData.userData.shippingAddress.zip}</h4>
                                </div>
                                <div>
                                    <small>Country</small>
                                    <h4>{responsData.userData.sameAddress ? responsData.userData.billingAddress.country : responsData.userData.shippingAddress.country}</h4>
                                </div>
                            </div>
                        </div>

                        {/* END */}
                    </div>
                </div>
                :
                    <Loading status={isLoading}/>
                }
            </Layout>

        </Fragment>
    );

}
