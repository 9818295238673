import React from 'react';
import { useBarcode } from 'react-barcodes'


//
//
export default function BarCode(props){

    const { inputRef } = useBarcode({
        value: props.id,
        options: {
            background: '#ffffff',
        }
    });

  return (<img ref={inputRef}/>)

}
