import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Plus, Move, X, Save, ChevronDown, ChevronUp } from 'react-feather';
import Nestable from 'react-nestable';



// components
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import TopBar from '../components/TopBar'


// controllers
import { _apiGETone, _apiPUT } from '../controllers/Api';
import { _toaster } from '../controllers/Helpers';


//
//
export default function PageShippingOptions(props){

    const auth = useSelector(state => state.auth);
    const id = '6079e96684ee795d0f51cafb';

    const fields = {
        _id: null,
        name: '',
        items: []
    };

    const [isLoading, setLoading] = React.useState(true);
    const [responseData, setResponseData] = React.useState(fields);


    //
    //
    const _submit = async() => {
        
        const update = await _apiPUT('/misc/update/'+id, responseData)
        if(!update.error){
            _toaster('Success', update.message, 'success')
        }


    } 
    


    //
    //
    const _addItem = () => {
        // setCarouselItemsCount(carouselItemsCount + 1);
        setResponseData({
            ...responseData,
            items:[...responseData.items, { id: new Date().getTime(), title: '', w1:0, w2:0, w3:0, w4:0, w5:0, image:'', expand:true}]
        });
    }


    //
    //
    const _updateValue = (name, value, index) => {
        let newArray = [...responseData.items];
        newArray[index] = { ...newArray[index], [name]: value };
        setResponseData({
            ...responseData,
            items:newArray
        });
    }


    //
    //
    const _removeItem = (id) => {
        let newArray = [...responseData.items];
        let filtered = newArray.filter(item=> item.id !== id);
        setResponseData({
            ...responseData,
            items:filtered
        });
    }



    //
    //
    const Handler = () => {
        return <button type="button" className="flex justify-center items-center w-10 h-10 text-gray-500 hover:text-black"><Move size="16"/></button>;
    };


    //
    //;
    const _item = ({item, index, handler}) => {
      return (
        <div className={`box relative my-5 ${!item.expand ? 'flex flex-row-reverse justify-between items-center' : 'p-5 border-r-[40px] border-gray-200'}`} key={'item'+index}>
            <div className={`${!item.expand ? 'flex flex-row-reverse justify-end items-center' : 'absolute top-0 -right-10 w-10'}`}>
                <div className={`${!item.expand ? '' : 'py-2'}`}>{handler}</div>
                <button type="button" className="bg-gray-400 hover:bg-red-500 text-white flex justify-center items-center w-10 h-10" onClick={()=>_removeItem(item.id)}><X size="16" /></button>
                <button type="button" className="bg-gray-300 hover:bg-black text-white flex justify-center items-center w-10 h-10" onClick={()=>_updateValue('expand', item.expand ? false : true, index)}>{item.expand ? <ChevronUp size="16"/> : <ChevronDown size="16" />}</button>
            </div>
            {item.expand ?  
            <div className="w-full">
                <div className={`formGroup`}>
                    <label>Title</label>
                    <div className="relative">
                        <input type={'text'} name="title" className={`formControl`} value={item.title} onChange={(e)=>{ _updateValue('title', e.target.value, index) }}/>
                    </div>
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-5 gap-2">
                    
                    <div className={`formGroup`}>
                        <label>0 - 0.20 kg</label>
                        <div className="relative">
                            <input type={'text'} name="w1" className={`formControl`} value={item.w1} onChange={(e)=>{ _updateValue('w1', e.target.value, index) }}/>
                        </div>
                    </div>
                    <div className={`formGroup`}>
                        <label>0.20 - 1 kg</label>
                        <div className="relative">
                            <input type={'text'} name="w2" className={`formControl`} value={item.w2} onChange={(e)=>{ _updateValue('w2', e.target.value, index) }}/>
                        </div>
                    </div>
                    <div className={`formGroup`}>
                        <label>1 - 2 kg</label>
                        <div className="relative">
                            <input type={'text'} name="w3" className={`formControl`} value={item.w3} onChange={(e)=>{ _updateValue('w3', e.target.value, index) }}/>
                        </div>
                    </div>
                    <div className={`formGroup`}>
                        <label>2 - 5 kg</label>
                        <div className="relative">
                            <input type={'text'} name="w4" className={`formControl`} value={item.w4} onChange={(e)=>{ _updateValue('w4', e.target.value, index) }}/>
                        </div>
                    </div>
                    <div className={`formGroup`}>
                        <label>5 - 10 kg</label>
                        <div className="relative">
                            <input type={'text'} name="w5" className={`formControl`} value={item.w5} onChange={(e)=>{ _updateValue('w5', e.target.value, index) }}/>
                        </div>
                    </div>

                </div>
            </div>
            :
            <h4 className="pl-4 py-2 font-bold">{item.title}</h4>
            }
        </div>
      );
  
    }


    //
    //
    React.useEffect(()=>{
        const init = async () =>{
            const get = await _apiGETone('/misc/single/'+id, true)
            if(!get.error){
                console.log(get.data)
                setResponseData(get.data)
            }
            setLoading(false)
        }
        init()
    },[])


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Products / Shipping options | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Products"
                        title="Shipping options"
                    >
                        <button type="button" onClick={()=> _submit()} className="button primary sm hidden md:flex"><Save size="16" className="mr-2"/> Save</button>
                        <a href="#" className="button dark sm ml-2 hidden md:flex" onClick={()=>_addItem()}><Plus size={16}/> Add item</a>
                    </TopBar>
                    <div className="_inner">
                        {/* START */}

                        <div className='flex gap-1 md:hidden mb-4'>
                            <button type="button" onClick={()=> _submit()} className="button primary sm w-full"><Save size="16" className="mr-2"/> Save</button>
                            <a href="#" className="button dark sm w-full" onClick={()=>_addItem()}><Plus size={16}/> Add item</a>
                        </div>

                        {responseData.items && 
                        <Nestable
                            items={responseData.items}
                            renderItem={_item}
                            onChange={(event)=> setResponseData({...responseData, items:event.items}) }
                            maxDepth={0}
                            handler={<Handler />}
                        />
                        }
                        {responseData.items && responseData.items.length===0 && !isLoading && <p className="text-center py-10">There's no items, use <strong>Add item</strong> add new one.</p>}

                        {/* END */}
                    </div>
                </div>
            </Layout>

        </Fragment>
    );

}
