import React from 'react';


//
//
export default function Loading(props){

  return (
    <div className={`_loading ${props.status ? ' ' : ' hide'}`}>
      <div><i></i><i></i><i></i></div>
    </div>
  )

}
