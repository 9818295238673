import React from 'react';
import { AlignLeft, WifiOff } from 'react-feather';
import { Offline, Online } from 'react-detect-offline'


//
//
export default function TopBar(props){


  const [ isOpen, setIsOpen ] = React.useState(false);


  //
  //
  React.useEffect(()=>{
    if(isOpen){
      document.body.classList.add('fixed');
    }else{
      document.body.classList.remove('fixed');
    }
  },[isOpen])


  //
  //
  return (
    <>
      <Online>
        <div className={`_topbar`}>
          <div className="flex items-center gap-4">
              <AlignLeft size={30} className='cursor-pointer triggerCollapse' onClick={()=>setIsOpen(!isOpen)}/>
              <div className="flex flex-col leading-tight">
              {props.overTitle && <span className="uppercase text-xxs">{props.overTitle} / </span>}
              {props.title && <span className="font-bold uppercase text-sm md:text-lg">{props.title}</span>}
              </div>
          </div>
          <div className="flex items-center justify-end">{props.children}</div>
        </div>
      </Online>
      <Offline>
        <div className={`_topbar offline`}>
          <div className="flex items-center">
              <WifiOff size={30}/>
              <span className="ml-4 font-bold uppercase text-lg">No internet</span>
          </div>
        </div>
      </Offline>
    </>
  )

}
