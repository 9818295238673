import React from 'react';


//
//
export default function Layout({children, css, ...props}){

  return (
    <div {...props}>
        {children}
    </div>
  )

}
