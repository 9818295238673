import React, { Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'


// controllers
import { inArray, _log } from './Helpers';
import history from './History';
import { _apiGETone } from './Api';
import _actions from './redux/actions'


// components
import Loading from '../components/Loading';


// pages
import PageLogin from '../pages/Login';

import PageDashboard from '../pages/Dashboard';
import PageMediaManager from '../pages/MediaManager'
import PageInstagram from '../pages/Instagram'
import PageTopBrands from '../pages/TopBrands'

import PageOrders from '../pages/transactions/orders';
import PageOrderDetails from '../pages/transactions/orders/details';
import PageDonations from '../pages/transactions/donations';
import PagePayouts from '../pages/transactions/payouts';
import PagePayoutDetails from '../pages/transactions/payouts/details';

import PageUsers from '../pages/users'
import PageUserDetails from '../pages/users/user'

import PageProducts from '../pages/products'
import PageProductDetails from '../pages/products/product'

import PageAttributes from '../pages/attributes'
import PageAttributeDetails from '../pages/attributes/attribute'

import PageShippingOptions from '../pages/ShippingOptions'
import PageProductsCategories from '../pages/ProductCategories'
import PageChangeOwnership from '../pages/ChangeOwnership'



// default - public
import Page404 from '../pages/404';
import Page403 from '../pages/403';


function PrivateRoute({ children, ...rest }) {

  const auth = useSelector(state => state.auth);
  const permissionLevel = rest.role && auth.status ? inArray(parseInt(auth.response.role), rest.role) : true;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.status ? (
          permissionLevel ?
          children :
          <Redirect
            to={{
              pathname: "/403",
              state: { from: location }
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


function AppRouter() {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);


  //
  //
  React.useEffect(()=>{

    const checkToken = async() => {
      const get = await _apiGETone('/auth/')
      if(get.error){
        _log("AppRouter | redirected", 'Token expired')
        history.replace('/')
      }
      if(get.token){
        _log("AppRouter | updated", 'Token updated')
        dispatch(_actions.auth.authUpdate({...auth.response, token:get.token}))
      }
    }
    checkToken()
  },[dispatch,auth.response])


  //
  //
  return (
    auth ? 
    <Router basename={'/'} history={history}>
      <Suspense fallback={<Loading status={true}/>}>
          <Switch>

            {/* PUBLIC ROUTES if auth===false */}
            <Route exact path="/">
              <PageLogin/>
            </Route>
            {/* <Route exact path="/choose-wallet/:type/">
              <PageChooseWallet/>
            </Route>
            <Route exact path="/setup/:type/">
              <PageSetUp/>
            </Route> */}

            
            {/* Dashboard */}
            <PrivateRoute exact path="/dashboard/" role={[1,2,3]}>
              <PageDashboard/>
            </PrivateRoute>

            
            {/* Trasnactions */}
            <PrivateRoute exact path="/transactions/orders" role={[1,2,3]}>
              <PageOrders/>
            </PrivateRoute>
            <PrivateRoute exact path="/transactions/orders/:id" role={[1,2,3]}>
              <PageOrderDetails/>
            </PrivateRoute>
            <PrivateRoute exact path="/transactions/donations" role={[1,2,3]}>
              <PageDonations/>
            </PrivateRoute>
            <PrivateRoute exact path="/transactions/payouts" role={[1,2,3]}>
              <PagePayouts/>
            </PrivateRoute>
            <PrivateRoute exact path="/transactions/payouts/:id" role={[1,2,3]}>
              <PagePayoutDetails/>
            </PrivateRoute>

            
            {/* products */}
            <PrivateRoute exact path="/products/all" role={[1,2,3]}>
              <PageProducts/>
            </PrivateRoute>
            <PrivateRoute exact path="/product/:id" role={[1,2,3]}>
              <PageProductDetails/>
            </PrivateRoute>

            
            {/* product attributes */}
            <PrivateRoute exact path="/products/attributes" role={[1,2,3]}>
              <PageAttributes/>
            </PrivateRoute>
            <PrivateRoute exact path="/products/attribute/:id" role={[1,2,3]}>
              <PageAttributeDetails/>
            </PrivateRoute>

            
            {/* product categories */}
            <PrivateRoute exact path="/products/categories/:id" role={[1,2,3]}>
              <PageProductsCategories/>
            </PrivateRoute>

            
            {/* shipping options */}
            <PrivateRoute exact path="/products/shipping" role={[1,2,3]}>
              <PageShippingOptions/>
            </PrivateRoute>

            
            {/* change ownership */}
            <PrivateRoute exact path="/products/change-ownership" role={[1,2,3]}>
              <PageChangeOwnership/>
            </PrivateRoute>

            
            {/* users */}
            <PrivateRoute exact path="/users" role={[1,2,3]}>
              <PageUsers/>
            </PrivateRoute>
            <PrivateRoute exact path="/user/:id" role={[1,2,3]}>
              <PageUserDetails/>
            </PrivateRoute>

            
            {/* media manager */}
            <PrivateRoute exact path="/media" role={[1,2,3]}>
              <PageMediaManager/>
            </PrivateRoute>

            
            {/* instagram */}
            <PrivateRoute exact path="/instagram" role={[1,2,3]}>
              <PageInstagram/>
            </PrivateRoute>

            
            {/* TopBrands */}
            <PrivateRoute exact path="/top-brands" role={[1,2,3]}>
              <PageTopBrands/>
            </PrivateRoute>


            {/* CUSTOMER ROUTES */}
            {/* <PrivateRoute exact path="/customer/dashboard/">
              <PageCustomerDashboard/>
            </PrivateRoute>
            <PrivateRoute exact path="/customer/exchanges/">
              <PageCustomerExchanges/>
            </PrivateRoute>
            <PrivateRoute exact path="/customer/upgrade/">
              <PageUpgradeToAffiliate/>
            </PrivateRoute> */}

            
            {/* AFFILIATE ROUTES */}
            {/* <PrivateRoute exact path="/affiliate/dashboard/">
              <PageAffiliateDashboard/>
            </PrivateRoute>
            <PrivateRoute exact path="/affiliate/my-customers/">
              <PageMyCustomers/>
            </PrivateRoute> */}


            {/* ERROR ROUTES */}
            <Route exact path="/404">
              <Page404/>
            </Route>
            <Route exact path="/403">
              <Page403/>
            </Route>
            <Route path="*">
              <Redirect
                to={{
                  pathname: "/404"
                }}
              />
            </Route>

          </Switch>
            

          <ToastContainer
            position="top-right"
          />
      </Suspense>
    </Router>
    :
    <Loading 
      status={true}
    />
  );
}

export default AppRouter;

// <Route exact path="/:permalink">
//   <PageGeneric sidebar={_sidebar}/>
// </Route>
