import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Loader } from 'react-feather';

import { ResponsiveCalendar } from '@nivo/calendar'
import { ResponsiveLine } from '@nivo/line'



// components
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import TopBar from '../components/TopBar'


// controllers
import { _apiGETone } from '../controllers/Api';


//
//
export default function PageDashboard(props){

    const auth = useSelector(state => state.auth);

    const [isLoading, setLoading] = React.useState(true);
    const [totals, setTotals] = React.useState({});
    const [dataOrdersByDay, setDataOrdersByDay] = React.useState([]);
    const [dataOrdersByMonthAndStatus, setDataOrdersByMonthAndStatus] = React.useState([]);


    //
    //
    React.useEffect(()=>{
        const init = async () =>{
            const get = await _apiGETone('/dashboard/totals/', true)
            if(!get.error){
                setDataOrdersByDay(get.byDay)
                setDataOrdersByMonthAndStatus(get.byStatus)
                setTotals(get.totals)
            }
            setLoading(false)
        }
        init()
    },[])


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Dashboard | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        title="Dashboard"
                    />
                    <div className="_inner">
                        {/* START */}

                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                                <div className="box p-10 flex flex-col justify-center">
                                    {isLoading 
                                        ? 
                                            <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div> 
                                        :
                                        <>
                                            <p className="text-primary text-sm">IN PROGRESS</p>
                                            <h2 className="text-4xl">{totals?.confirmed} <small className="text-sm text-gray-400">in current status</small></h2>
                                        </>
                                    }
                                </div>
                                <div className="box p-10 flex flex-col justify-center">
                                    {isLoading 
                                        ? 
                                            <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div> 
                                        :
                                        <>
                                            <p className="text-primary text-sm">COMPLETED</p>
                                            <h2 className="text-4xl">{totals?.completed} <small className="text-sm text-gray-400">in current status</small></h2>
                                        </>
                                    }
                                </div>
                                <div className="box p-10 flex flex-col justify-center">
                                    {isLoading 
                                        ? 
                                            <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div> 
                                        :
                                        <>
                                            <p className="text-primary text-sm">FAILED</p>
                                            <h2 className="text-4xl">{totals?.failed} <small className="text-sm text-gray-400">in current status</small></h2>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="box p-4">
                                {isLoading 
                                    ? 
                                        <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div> 
                                    :
                                    <>
                                        <ResponsiveCalendar
                                            data={dataOrdersByDay}
                                            from="2021-01-01"
                                            to="2021-12-31"
                                            emptyColor="#eeeeee"
                                            colors={[ '#61cdbb', '#97e3d5', '#e8c1a0', '#f47560' ]}
                                            margin={{ top: 20, right: 30, bottom: 0, left: 30 }}
                                            yearSpacing={40}
                                            monthBorderColor="#ffffff"
                                            dayBorderWidth={2}
                                            dayBorderColor="#ffffff"
                                            legends={[
                                                {
                                                    anchor: 'bottom-right',
                                                    direction: 'row',
                                                    translateY: 36,
                                                    itemCount: 4,
                                                    itemWidth: 42,
                                                    itemHeight: 36,
                                                    itemsSpacing: 14,
                                                    itemDirection: 'right-to-left'
                                                }
                                            ]}
                                        />
                                    </>
                                }
                            </div>
                        </div>

                        {isLoading 
                            ? 
                                <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div> 
                            :
                            <>
                            <ResponsiveLine
                                data={dataOrdersByMonthAndStatus}
                                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                                yFormat=" >-.2f"
                                axisTop={null}
                                axisRight={null}
                                axisLeft={{
                                    orient: 'left',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'total orders',
                                    legendOffset: -40,
                                    legendPosition: 'middle'
                                }}
                                pointSize={10}
                                pointColor={{ theme: 'background' }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabelYOffset={-12}
                                useMesh={true}
                                legends={[
                                    {
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemDirection: 'left-to-right',
                                        itemWidth: 80,
                                        itemHeight: 20,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: 'circle',
                                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                />
                            </>
                        }

                        {/* END */}
                    </div>
                </div>
            </Layout>

        </Fragment>
    );

}
