import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom';
import { Loader, ChevronLeft, ChevronRight } from 'react-feather';
import ReactPaginate from 'react-paginate'



// components
import Layout from '../../../components/Layout'
import Sidebar from '../../../components/Sidebar'
import TopBar from '../../../components/TopBar'
import Table from '../../../components/Table';


// controllers
import { _apiGETmulti } from '../../../controllers/Api';
import useQuery from '../../../controllers/hooks/useQuery'


//
//
export default function PageDonations(props){

    const auth  = useSelector(state => state.auth);
    const query = useQuery();
    const history = useHistory()

    const fields = {
        limit: 15,
        offset: 0,
        orderBy: 'dateCreated|desc',
        organisation: 'all'
    }

    const [ isLoading, setLoading ] = React.useState(false);
    const [ requestData, setRequestData ] = React.useState(fields);
    const [ responsData, setResponseData ] = React.useState([]);
    const [ total, setTotal ] = React.useState(0);


    //
    //
    const _updateField = (field, data) => {
        setRequestData({...requestData, [field]:data});
    }


    //
    //
    const _paginateAction = (e) => {
        history.push('/transactions/payouts/?page='+e.selected)
    }


    //
    //
    const _query = async() => {

        setLoading(true)

        const get = await _apiGETmulti('/donations/query/', requestData)
        if(!get.error){
            setResponseData(get.data);
            setTotal(get.total);
        }
        setLoading(false)
    }


    //
    //
    React.useEffect(()=>{
        _query();
    },[requestData]);


    //
    //
    React.useEffect(()=>{

        const _updateRequest = () => {
            const organisation = query.get('organisation')
            const page = query.get('page')

            let newRequest = {...requestData}
            if(organisation){
                newRequest.organisation = organisation
            }
            if(page){
                newRequest.offset = parseInt(page) === 0 ? 0 : (parseInt(page)*fields.limit)
            }

            if(JSON.stringify(newRequest) !== JSON.stringify(requestData)){
                setRequestData(newRequest)
            }
        }
        _updateRequest()

    },[query]);


    //
    //
    const _title = (data) => {
        return(
            <Link to={{pathname:'/transactions/order/'+data._id}} className="link">
              <strong>{data.name}</strong>
            </Link>
        )
    }


    //
    //
    const _from = (data) => {
        return(
            <>
            <div>{data.userData.firstName} {data.userData.lastName}</div>
            <small>{data.userData.email}</small>
            </>
        )
    }


    //
    //
    const _total = (data) => {
        return process.env.REACT_APP_CURRENCY+(data.amount).toFixed(2)
    }


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Donations - Transactions | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Transactions"
                        title="Donations"
                    />
                    <div className="_inner">
                        {/* START */}

                        {total===0 ?
                            <div className="text-center py-4">
                                {isLoading 
                                ? 
                                <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div> 
                                : <>{requestData.organisation==='all' ? 'There\'s no donations on 2t2t.london.' : 'There\'s no donations for selected organisation.'}</>
                                }
                            </div>
                        :
                            <>
                            <Table
                                action={_updateField}
                                perPage={requestData.limit}
                                sortBy={requestData.orderBy}
                                head={
                                [
                                    {name:'Organisation', width:'auto', sortable:true, key:'_', type:'function', func:_title},
                                    {name:'Created on', width:'15%', sortable:true, key:'dateCreated', type:'date'},
                                    {name:'From', width:'15%', sortable:false, key:'_', type:'function', func:_from},
                                    {name:'Total', width:'10%', sortable:false, key:'_', type:'function', func:_total}
                                ]
                                }
                                body={responsData}
                                total={total}
                            />
                            <ReactPaginate
                                previousLabel={<ChevronLeft/>}
                                nextLabel={<ChevronRight/>}
                                breakLabel={'...'}
                                pageCount={Math.round(total/fields.limit)}
                                pageRangeDisplayed={5}
                                onPageChange={_paginateAction}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                renderOnZeroPageCount={null}
                                forcePage={requestData.offset/requestData.limit}
                            />
                            </>
                        }

                        {/* END */}
                    </div>
                </div>
            </Layout>

        </Fragment>
    );

}
