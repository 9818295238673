import React, { Fragment } from 'react'
import { useField } from '@unform/core'


//
export default function TextArea({name, type, label, availability, className, ...props}) {

    const inputRef = React.useRef(null);
    const { fieldName, defaultValue, registerField, error, clearError } = useField(name)


    React.useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])


    //
    //
    return (
        <Fragment>
            <div className={`formGroup ${className ? className : ''}`}>
                {label && <label>{label}</label>}
                <div className="relative">
                  <textarea className={`formControl ${error ? 'error' : ''}`} ref={inputRef} defaultValue={defaultValue} onFocus={clearError} {...props}/>
                  {availability && <div className={`status ${availability==='true' ? 'success' : 'error'}`}>{availability==='true' ? 'available' : 'unavailable'}</div>}
                </div>
                { error && <span className="error">{error}</span> }
            </div>
        </Fragment>
    );
};