import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import { Loader, ChevronLeft, ChevronRight, Info, Plus, AlertTriangle, ShoppingBag, XOctagon, Save } from 'react-feather';
import { Form } from '@unform/web'
import * as Yup from 'yup';
import Nestable from 'react-nestable';



// components
import Layout from '../../components/Layout'
import Sidebar from '../../components/Sidebar'
import TopBar from '../../components/TopBar'
import Input from '../../components/Forms/Input'
import SingleSelect from '../../components/Forms/Select'
import Modal from '../../components/Modal';


// controllers
import { _apiGETone, _apiGETmulti, _apiPUT, _apiPOST } from '../../controllers/Api';
import { _status as _Status, _toaster } from '../../controllers/Helpers'


//
//
export default function PageUserDetails(props){

    const auth  = useSelector(state => state.auth);
    const formRef = React.useRef(null);
    const formAddBalanceRef = React.useRef(null);
    const history = useHistory()
    const { id } = useParams();

    const [ isLoading, setLoading ] = React.useState(true);
    const [ responsData, setResponseData ] = React.useState(null);

    const [ totalPurchased, setTotalPurchased ] = React.useState(0)
    const [ totalSold, setTotalSold ] = React.useState(0)
    const [ totalWishlist, setTotalWishlist ] = React.useState(0)
    const [ totalActiveProducts, setTotalActiveProducts] = React.useState(0)

    const [ available1, setAvailable1 ] = React.useState(false)
    const [ available2, setAvailable2 ] = React.useState(false)
    const [ userStatus, setUserStatus ] = React.useState(true);
    const [ isAmbasador, setIsAmbasador ] = React.useState(false);

    const [ modalAddBalance, setModalAddBalance ] = React.useState(false)

    const roles = [
        {_id:'1', title:'Super administrator'},
        {_id:'2', title:'Administrator'},
        {_id:'4', title:'Member'}
    ]


    //
    //
    const _setDefaults = () => {
        if(responsData && id !== 'new'){

            formRef.current.setFieldValue('username', responsData.username);
            formRef.current.setFieldValue('firstName', responsData.firstName);
            formRef.current.setFieldValue('lastName', responsData.lastName);
            formRef.current.setFieldValue('email', responsData.email);
            formRef.current.setFieldValue('phone', responsData.phone ? (responsData.phone).toString() : '');
            formRef.current.setFieldValue('productsLimit', responsData.productsLimit ? responsData.productsLimit : 100);
            
            const role = roles.find(x => x._id===responsData.role)
            if(role){
                formRef.current.setFieldValue('role', role);
            }

            setAvailable1(true)
            setAvailable2(true)
            setUserStatus(responsData.status==='active' ? true : false)
            setIsAmbasador(responsData.isAmbasador ? true : false)

        }else{
            formRef.current.setFieldValue('productsLimit', 100);
            formRef.current.setFieldValue('role', '4');
        }
    }


    //
    //
    const _getBuyTransactions = async() => {
        const get = await _apiGETmulti('/public/transactions/orders/', {userId:id, limit:3, offset:0})
        if(!get.error){
            setTotalPurchased(get.total)
        }
    }


    //
    //
    const _getSellTransactions = async() => {
        const get = await _apiGETmulti('/public/transactions/sells/', {sellerId:id, limit:3, offset:0})
        if(!get.error){
            setTotalSold(get.total)
        }
    }


    //
    //
    const _getDashboard = async() => {
        const get = await _apiGETmulti('/public/dashboard/'+id, {type: 'products'})
        if(!get.error){
            setTotalWishlist(get.totalWishlist)
            setTotalActiveProducts(get.totalActiveProducts)
        }
    }


    //
    //
    const _query = async() => {

        setLoading(true)

        if(id==='new'){
            setResponseData({});
        }else{
            const get = await _apiGETone('/user/seller-info/'+id)
            if(!get.error){
                setResponseData(get.data.member);
                await _getDashboard()
                await _getBuyTransactions()
                await _getSellTransactions()
            } 
        }
        setLoading(false)
    }


    //
    //
    React.useEffect(()=>{
        _query();
    },[]);


    //
    //
    React.useEffect(()=>{
        if(responsData){
            _setDefaults()
        }
    },[responsData]);



    //
    //
    const handleSubmitAddBalance = React.useCallback(async(data) => {
        try {
    
            // Remove all previous errors
            formAddBalanceRef.current.setErrors({});
            const schema = Yup.object().shape({
                value: Yup.number().typeError('Value must be in number format.').min(1, '1 is minimum').required(),
            });
            await schema.validate(data, {
                abortEarly: false,
            });

            // return;
            setLoading(true);
            const addBalance = await _apiPOST('/user/add-balance/', {...data, id:id})
            if(!addBalance.error){
                _toaster('Success', addBalance.message, 'success')
                formAddBalanceRef.current.reset()
                await _query();
                setModalAddBalance(false)
            }
            setLoading(false);
    
        } catch (err) {
    
          const validationErrors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
                console.log(error.path,error.message)
              validationErrors[error.path] = error.message;
            });
            formAddBalanceRef.current.setErrors(validationErrors);
          }
    
        }
    }, [id]);



    //
    //
    const handleSubmit = React.useCallback(async(data) => {
        try {
    
            // data.role = data.role.toString()
            // Remove all previous errors
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                username: Yup.string().required(),
                firstName: Yup.string().required(),
                lastName: Yup.string().required(),
                email: Yup.string().email().required(),
                // role: Yup.string().required(),
                productsLimit: Yup.number('Max. products must be number').required(),
                password: id==='new' ? Yup.string().min(8).required('Password is required field') : false,
                passwordConfirm: id==='new' ? Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match') : false,
            });
            await schema.validate(data, {
                abortEarly: false,
            });



            if(!data.role?._id){
                formRef.current.setFieldError('role', 'Role is required field');
                return;
            }


            data.role = data.role._id
            data.status = userStatus ? 'active' : 'inactive'
            data.isAmbasador = isAmbasador
            let formData = id==='new' ? data : {...responsData, ...data}

            // return;
            setLoading(true);
            if(id==='new'){


                if(!available1){
                    formRef.current.setFieldError('username', 'Username is taken');
                    return;
                }
                if(!available2){
                    formRef.current.setFieldError('email', 'Email is taken');
                    return;
                }
                
                const insert = await _apiPOST('/user/insert/', formData)
                if(!insert.error){
                    _toaster('Success', insert.message, 'success')
                    history.goBack()
                }


            }else{

                const update = await _apiPUT('/user/update/'+id, formData)
                if(!update.error){
                    _toaster('Success', update.message, 'success')
                    history.goBack()
                }

            }
            setLoading(false);
    
        } catch (err) {
    
          const validationErrors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
                console.log(error.path,error.message)
              validationErrors[error.path] = error.message;
            });
            formRef.current.setErrors(validationErrors);
          }
    
        }
    }, [history, userStatus, isAmbasador, responsData, id]);


    //
    //
    const _submit = () => {
        formRef.current.submitForm()
    } 



    //
    //
    const _checkAvailability = async(type,value) => {

        let _post = { username: value };
        let _route = '/public/auth/check/username/';
        if(type==='email'){
            _post = { email: value };
            _route = '/public/auth/check/email/';
        }

        const check = await _apiPOST(_route, _post)
        if(!check.error){
            if(type==='email'){
                setAvailable2(check.status);
            }else{
                setAvailable1(check.status);
            }
        }else{
            if(type==='email'){
                setAvailable2(false);
            }else{
                setAvailable1(false);
            }
        }

    }


    //
    //
    const _isAvailable1 = value => {
        const timeoutId = setTimeout(() => value !== '' ? _checkAvailability('username', value) : null, 1000 );
        return () => clearTimeout(timeoutId);
    }


    //
    //
    const _isAvailable2 = value => {
        const timeoutId = setTimeout(() => value !== '' ? _checkAvailability('email', value) : null, 1000 );
        return () => clearTimeout(timeoutId);
    }




    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>{process.env.REACT_APP_NAME}{`${responsData && responsData.title} - Products | ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                {responsData ? 
                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Users"
                        title={id==='new' ? 'New user' : responsData.firstName+' '+responsData.lastName}
                    >
                        <button type="button" onClick={()=> _submit()} className="button primary sm hidden md:flex"><Save size="16" className="mr-2"/> Save</button>
                        <a href="#" className="button dark sm ml-2 hidden md:flex" onClick={()=> history.goBack()}><ChevronLeft size={16}/> Back</a>
                    </TopBar>

                    <div className="_inner">
                        {/* START */}

                        <div className='flex gap-1 md:hidden mb-4'>
                            <button type="button" onClick={()=> _submit()} className="button primary sm w-full"><Save size="16" className="mr-2"/> Save</button>
                            <a href="#" className="button dark sm w-full" onClick={()=> history.goBack()}><ChevronLeft size={16}/> Back</a>
                        </div>

                        <Form ref={formRef} onSubmit={handleSubmit} className="w-full" autoComplete="off">

                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                            
                                <div>
                                    <div className="box p-6 my-6">
                                        <h4 className="mb-2 font-bold">Profile information</h4>

                                        <div className="formGroup mt-4">
                                            <div className="switch mt-2">
                                                <input type="checkbox" name="type" value="" checked={userStatus} onChange={()=> setUserStatus(!userStatus)}/>
                                                <div className="mr-2"><i></i></div>
                                                <label>Status - <strong className="uppercase">{userStatus ? 'Active' : 'Inactive'}</strong></label>
                                            </div>
                                        </div>
                                        <hr className='mt-4'/>
                                        <div className="formGroup mt-4">
                                            <div className="switch mt-2">
                                                <input type="checkbox" name="type" value="" checked={isAmbasador} onChange={()=> setIsAmbasador(!isAmbasador)}/>
                                                <div className="mr-2"><i></i></div>
                                                <label>2t2t ambasador - <strong className="uppercase">{isAmbasador ? 'Yes' : 'No'}</strong></label>
                                            </div>
                                        </div>
                                        <Input name="username" label="username" disabled={id==='new' ? false : true} onChange={(e)=> _isAvailable1(e.target.value)} availability={available1.toString()}/>
                                        <div className="grid grid-cols-2 gap-6">
                                            <Input name="firstName" label="First name"/>
                                            <Input name="lastName" label="Last name"/>
                                        </div>
                                        <div className="grid grid-cols-2 gap-6">
                                            <Input name="email" label="Email" disabled={id==='new' ? false : true} onChange={(e)=> _isAvailable2(e.target.value)} availability={available2.toString()}/>
                                            <Input name="phone" label="Phone"/>
                                        </div>
                                    </div>
                                    <div className="box p-6 my-6">
                                        <div className="mb-2">
                                            <h4 className="font-bold">Profile settings</h4>
                                        </div>
                                        <div className="grid grid-cols-2 gap-6">
                                            <Input name="productsLimit" label="Maximum allowed products" type="number" step="1"/>
                                            <SingleSelect name="role" data={roles} label="Role" disabled={id==='new' ? false : true}/>
                                        </div>
                                    </div>
                                    <div className="box p-6 my-6">
                                        <div className="mb-2">
                                            <h4 className="font-bold">Change password</h4>
                                            {id !== 'new' && <small>if you do not want to change the password, leave the fields blank</small>}
                                        </div>
                                        <div className="grid grid-cols-2 gap-6">
                                            <Input name="password" type="password" label="Password" autoComplete="new-password"/>
                                            <Input name="password_confirm" type="password" label="Repeat password"/>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    {responsData.role==='4' && id !== 'new' &&
                                    <>
                                    <div className='flex justify-start items-center gap-4'>
                                        <div>
                                        <h2 className="text-4xl mt-8">{process.env.REACT_APP_CURRENCY+(responsData.balanceRegular ? responsData.balanceRegular.toFixed(2) : '0.00')}</h2>
                                        <p className="text-gray-500 text-xs">CURRENT BALANCE</p>
                                        </div>
                                        <button type="button" onClick={()=> setModalAddBalance(true)} className="button secondary sm mt-3">Add to balance</button>
                                    </div>
                                    <div className="grid grid-cols-4 gap-4 mt-6">
                                        <div>
                                            <h2 className="text-4xl">{totalSold}</h2>
                                            <p className="text-gray-500 text-xs">Total sold products</p>
                                        </div>
                                        <div>
                                            <h2 className="text-4xl">{totalActiveProducts}</h2>
                                            <p className="text-gray-500 text-xs">Total active products</p>
                                        </div>
                                        <div>
                                            <h2 className="text-4xl">{totalWishlist}</h2>
                                            <p className="text-gray-500 text-xs">Items in wishlist</p>
                                        </div>
                                    </div>
                                    </>
                                    }

                                    {id !== 'new' && <hr className="mt-10 mb-6"/>}

                                    {responsData.role==='4' && id !== 'new' && 
                                    <>
                                    <h4 className="mt-6 font-bold">Acceptances</h4>
                                    <div className="grid grid-cols-2 gap-x-4 items-end">
                                        <div className="formGroup">
                                            <label>I confirm to receive notifications from 2t2t.london</label>
                                            <p className="text-sm font-bold">{responsData.acceptanceNotification ? 'YES' : 'NO'}</p>
                                        </div>
                                        <div className="formGroup">
                                            <label>I confirm that I’m over 18 years old</label>
                                            <p className="text-sm font-bold">{responsData.acceptancePlus18 ? 'YES' : 'NO'}</p>
                                        </div>
                                    </div>
                                    </>
                                    }

                                    {responsData.role==='4' && id !== 'new' &&
                                    <>
                                    <h4 className="mt-6 font-bold">Addresses</h4>
                                    <div className="grid grid-cols-2 gap-x-4">
                                        <div className="formGroup">
                                            <label>Billing address</label>
                                            <p className="text-sm">
                                                {responsData.billingAddress[0] ? 
                                                    <>
                                                    {responsData.billingAddress[0].country}<br/>
                                                    {responsData.billingAddress[0].city}, {responsData.billingAddress[0].zip}<br/>
                                                    {responsData.billingAddress[0].address}
                                                    </>
                                                    : '-'
                                                }
                                            </p>
                                        </div>
                                        <div className="formGroup">
                                            <label>Shipping address</label>
                                            <p className="text-sm">
                                                {responsData.sameAddress ? 
                                                    <>
                                                        {responsData.billingAddress[0] ? 
                                                        <>
                                                        {responsData.billingAddress[0].country}<br/>
                                                        {responsData.billingAddress[0].city}, {responsData.billingAddress[0].zip}<br/>
                                                        {responsData.billingAddress[0].address}
                                                        </>
                                                        : '-'
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                        {responsData.shippingAddress[0] ? 
                                                        <>
                                                        {responsData.shippingAddress[0].country}<br/>
                                                        {responsData.shippingAddress[0].city}, {responsData.shippingAddress[0].zip}<br/>
                                                        {responsData.shippingAddress[0].address}
                                                        </>
                                                        : '-'
                                                    }
                                                    </>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    </>
                                    }

                                    {responsData.role==='4' && id !== 'new' && responsData.isCompany &&
                                    <>
                                    <h4 className="mt-6 font-bold">Company Info</h4>
                                    <div className="grid grid-cols-2 gap-x-4">
                                        <div className="formGroup">
                                            <label>Company name</label>
                                            <p className="text-sm">{responsData.companyName ? responsData.companyName : '-'}</p>
                                        </div>
                                        <div className="formGroup">
                                            <label>VAT number</label>
                                            <p className="text-sm">{responsData.companyVAT ? responsData.companyVAT : '-'}</p>
                                        </div>
                                        <div className="formGroup">
                                            <label>Company address</label>
                                            <p className="text-sm">{responsData.companyAddress ? responsData.companyAddress : '-'}</p>
                                        </div>
                                    </div>
                                    </>
                                    }

                                    {responsData.role==='4' && id !== 'new' && responsData.payoutInfo[0] &&
                                    <>
                                    <h4 className="mt-6 font-bold">Additional information for payouts</h4>
                                    {
                                        !responsData.payoutInfo[0].bankName && 
                                        !responsData.payoutInfo[0].sortCode && 
                                        !responsData.payoutInfo[0].accountNumber && 
                                        !responsData.payoutInfo[0].beneficiaryName ?
                                            <p>N/A</p>
                                        :
                                            <div className="grid grid-cols-2 gap-x-4">
                                                <div className="formGroup">
                                                    <label>Bank name</label>
                                                    <p className="text-sm">{responsData.payoutInfo[0].bankName ? responsData.payoutInfo[0].bankName : '-'}</p>
                                                </div>
                                                <div className="formGroup">
                                                    <label>Sort code</label>
                                                    <p className="text-sm">{responsData.payoutInfo[0].sortCode ? responsData.payoutInfo[0].sortCode : '-'}</p>
                                                </div>
                                                <div className="formGroup">
                                                    <label>Account number</label>
                                                    <p className="text-sm">{responsData.payoutInfo[0].accountNumber ? responsData.payoutInfo[0].accountNumber : '-'}</p>
                                                </div>
                                                <div className="formGroup">
                                                    <label>Beneficiary name</label>
                                                    <p className="text-sm">{responsData.payoutInfo[0].beneficiaryName ? responsData.payoutInfo[0].beneficiaryName : '-'}</p>
                                                </div>
                                            </div>
                                    }
                                    </>
                                    }


                                    {responsData.role==='4' && id !== 'new' && 
                                    <>
                                    <h4 className="mt-6 font-bold">Additional store/closet information</h4>
                                    {
                                        !responsData.storeName ?
                                            <p>N/A</p>
                                        :
                                            <>
                                            <div className="formGroup">
                                                <label>Store name</label>
                                                <p className="text-sm">{responsData.storeName ? responsData.storeName : '-'}</p>
                                            </div>
                                            <div className="formGroup">
                                                <label>Store description</label>
                                                <p className="text-sm">{responsData.storeDesc ? responsData.storeDesc : '-'}</p>
                                            </div>
                                            <div className="grid grid-cols-2 gap-x-4">
                                                <div className="formGroup">
                                                    <label>Return policy</label>
                                                    {!responsData.returnPolicy ? 
                                                        <p>'N/A'</p>
                                                        :
                                                        <p className="text-sm">
                                                            {responsData.returnPolicy && responsData.returnPolicy==1 && 'I accept returns according to 2T2T.LONDON return policy'}
                                                            {responsData.returnPolicy && responsData.returnPolicy==5 && 'I have my return policy'}
                                                        </p>
                                                    }
                                                    
                                                </div>
                                                <div className="formGroup">
                                                    <label>Shipping options</label>
                                                    <ul className="text-sm">
                                                    {!responsData.shippingOptions ? 
                                                        <p>'N/A'</p>
                                                        :
                                                        <ul className="text-sm">
                                                            {
                                                                responsData.shippingOptions.length && responsData.shippingOptions.map((item,index)=>{
                                                                    return <li key={index}>- {item.title}</li>
                                                                })
                                                            }
                                                        </ul>
                                                    }
                                                    </ul>
                                                </div>
                                            </div>
                                            </>
                                    }
                                    </>
                                    }
                                </div>

                            </div>

                            

                        </Form>

                        {/* END */}
                    </div>
                </div>
                : null
                }
            </Layout>


            <Modal
                status={modalAddBalance}
                showClose={true}
                close={()=>setModalAddBalance(false)}
                size="small"
            >
                <Form ref={formAddBalanceRef} onSubmit={handleSubmitAddBalance} className="w-full" autoComplete="off">
                    <Input name="value" label="Value"/>
                    <div className='grid grid-cols-2 gap-4'>
                        <button type="button" className='button secondary' onClick={()=>setModalAddBalance(false)}>cancel</button>
                        <button type="submit" className='button dark'>Add</button>
                    </div>
                </Form>
            </Modal>

        </Fragment>
    );

}
