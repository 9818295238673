import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Loader, ChevronLeft, ChevronRight, Info, Plus } from 'react-feather';
import ReactPaginate from 'react-paginate'



// components
import Layout from '../../components/Layout'
import Sidebar from '../../components/Sidebar'
import TopBar from '../../components/TopBar'
import Table from '../../components/Table';
import Search from '../../components/Search'


// controllers
import { _apiGETmulti } from '../../controllers/Api';
import useQuery from '../../controllers/hooks/useQuery'
import { _status as _Status } from '../../controllers/Helpers'


//
//
export default function PageProducts(props){

    const auth  = useSelector(state => state.auth);
    const query = useQuery();
    const history = useHistory()
    const location = useLocation()

    const fields = {
        limit: 10,
        offset: 0,
        orderBy: 'dateCreated|desc',
        search: ''
    }

    const [ isLoading, setLoading ] = React.useState(false);
    const [ requestData, setRequestData ] = React.useState(fields);
    const [ responsData, setResponseData ] = React.useState([]);
    const [ total, setTotal ] = React.useState(0);


    //
    //
    const _paginateAction = (e) => {
        _createRequest('page',e.selected)
    }


    //
    //
    const _query = async(data=requestData) => {

        setLoading(true)

        const get = await _apiGETmulti('/shop/products/query/', data)
        if(!get.error){
            setResponseData(get.data);
            setTotal(get.total);
        }
        setLoading(false)
    }


    //
    //
    const _createRequest = (field, data) => {
        const page = query.get('page')
        const orderBy = query.get('orderBy')
        const search = query.get('search')

        let newRequest = []

        if(page && field !== 'page' && field !== 'search'){
            newRequest.push('page='+page)
        }else if(field === 'page'){
            newRequest.push('page='+data)
        }

        if(orderBy && field !== 'orderBy'){
            newRequest.push('orderBy='+orderBy)
        }else if(field === 'orderBy'){
            newRequest.push('orderBy='+data)
        }

        if(search && field !== 'search'){
            newRequest.push('search='+search)
        }else if(field === 'search'){
            newRequest.push('search='+data)
            newRequest.push('page=0')
        }

        if(newRequest.length>0){
            history.push('/products/all/?'+newRequest.join('&'))
        }else{
            history.push('/products/all')
        }
    }


    //
    //
    const _updateRequest = () => {
        const page = query.get('page')
        const orderBy = query.get('orderBy')
        const search = query.get('search')

        let newRequest = {...requestData}
        if(page){
            newRequest.offset = parseInt(page) === 0 ? 0 : (parseInt(page)*fields.limit)
        }
        if(orderBy){
            newRequest.orderBy = orderBy
        }
        if(search !== null){
            newRequest.search = search
        }

        console.log('_updateRequest page:', page)
        console.log('_updateRequest orderBy:', orderBy)
        console.log('_updateRequest search:', search)
        console.log('_updateRequest:', newRequest)

        setRequestData(newRequest)
        _query(newRequest);
    }


    //
    //
    React.useEffect(()=>{
        console.log('trigger location',requestData, location)
        _updateRequest()
    },[location]);


    //
    //
    const _title = (data) => {
        return(
            <Link to={{pathname:'/product/'+data._id}} className="link">
             {data.title}<br/>
             <small>sku: {data.sku}</small>
            </Link>
        )
    }

    //
    //
    const _options = (data) => {
        return(
            <div className="flex justify-end">
            <Link to={{pathname:'/product/'+data._id}} className="w-6">
              <Info size={20}/>
            </Link>
            </div>
        )
    }

    //
    //
    const _image = (data) => {
        return data.featuredImage ? <img src={process.env.REACT_APP_UPLOAD_URL+'/'+data.featuredImage+'_400.jpg'} alt=""/> : <img src={'https://via.placeholder.com/400x300/e6deca/000000/?text=NO%20PHOTO'} alt=""/>
    }

    //
    //
    const _price = (data) => {
        return process.env.REACT_APP_CURRENCY+(data.price).toFixed(2)
    }


    //
    //
    const _status = (data) => {
        return _Status(data.status)
    }


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Products | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Products"
                        title="All products"
                    >
                        <Search 
                            isLoading={isLoading}
                            onChange={(value)=>_createRequest('search',value)}
                            className="hidden md:flex"
                        />
                        <Link to={'/product/new'} className="button dark sm ml-2"><Plus size={16} className="-ml-1 mr-1"/> Add</Link>
                    </TopBar>
                    <div className="_inner">
                        {/* START */}

                        <div className='md:hidden mb-4'>
                            <Search 
                                isLoading={isLoading}
                                onChange={(value)=>_createRequest('search',value)}
                                className="w-full"
                            />
                        </div>

                        {total===0 ?
                            <div className="text-center py-4">
                                {isLoading 
                                ? 
                                <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div> 
                                : <>There's no products registered yet.</>
                                }
                            </div>
                        :
                            <>
                            <Table
                                action={_createRequest}
                                perPage={requestData.limit}
                                sortBy={requestData.orderBy}
                                head={
                                [
                                    {name:'Image', width:'10%', sortable:false, key:'_', type:'function', func:_image, class:'image'},
                                    {name:'Title / SKU', width:'auto', sortable:true, key:'title', type:'function', func:_title},
                                    {name:'Status', width:'10%', sortable:true, key:'status', type:'function', func:_status},
                                    {name:'Price', width:'10%', sortable:true, key:'price', type:'function', func:_price},
                                    {name:'Qty', width:'10%', sortable:true, key:'qty'},
                                    {name:'Updated at', width:'15%', sortable:true, key:'dateUpdated', type:'date'},
                                    {name:'Options', width:'10%', sortable:false, key:'_', type:'function', func:_options}
                                ]
                                }
                                body={responsData}
                                total={total}
                            />
                            <ReactPaginate
                                previousLabel={<ChevronLeft/>}
                                nextLabel={<ChevronRight/>}
                                breakLabel={'...'}
                                pageCount={Math.round(total/fields.limit)}
                                pageRangeDisplayed={5}
                                onPageChange={_paginateAction}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                renderOnZeroPageCount={null}
                                forcePage={requestData.offset/requestData.limit}
                            />
                            </>
                        }

                        {/* END */}
                    </div>
                </div>
            </Layout>

        </Fragment>
    );

}
