import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Plus, Move, X, Save, ChevronDown, ChevronUp } from 'react-feather';
import Nestable from 'react-nestable';



// components
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import TopBar from '../components/TopBar'
import Media from '../components/Media';


// controllers
import { _apiGETone, _apiPUT } from '../controllers/Api';
import { _toaster } from '../controllers/Helpers';


//
//
export default function PageInstagram(props){

    const auth = useSelector(state => state.auth);
    const id = '6189a2ff6877b6468a2d2386';

    const fields = {
        _id: null,
        name: '',
        items: []
    };

    const [isLoading, setLoading] = React.useState(true);
    const [responseData, setResponseData] = React.useState(fields);


    //
    // Media modal
    const [mediaIndex, setMediaIndex] = React.useState(null);
    const [mediaState, setMediaState] = React.useState(false);
    const _mediaOpen = (i) => {
      setMediaIndex(i);
      setMediaState(true);
    }
    const _mediaClose = () => {
      setMediaIndex(null);
      setMediaState(false);
    }


    //
    //
    const _submit = async() => {
        
        const update = await _apiPUT('/misc/update/'+id, responseData)
        if(!update.error){
            _toaster('Success', update.message, 'success')
        }


    } 
    


    //
    //
    const _addItem = () => {
        // setCarouselItemsCount(carouselItemsCount + 1);
        setResponseData({
            ...responseData,
            items:[...responseData.items, { id: new Date().getTime(), title: '', link:'', image:'', expand:true}]
        });
    }


    //
    //
    const _updateValue = (name, value, index) => {
        let newArray = [...responseData.items];
        // let index = newArray.findIndex(item => item.id===id);
        // console.log(responseData.items, index, newArray);
        newArray[index] = { ...newArray[index], [name]: value };
        setResponseData({
            ...responseData,
            items:newArray
        });
    }


    //
    //
    const _removeItem = (id) => {
        let newArray = [...responseData.items];
        let filtered = newArray.filter(item=> item.id !== id);
        setResponseData({
            ...responseData,
            items:filtered
        });
    }


    //
    //
    const _insertMedia = (index, image) => {
        _mediaClose();
        _updateValue('fileName', image[0].fileName, index);
    }



    //
    //
    const Handler = () => {
        return <button type="button" className="flex justify-center items-center w-10 h-10 text-gray-500 hover:text-black"><Move size="16"/></button>;
    };


    //
    //;
    const _item = ({item, index, handler}) => {
      return (
        <div className={`box relative my-5 ${!item.expand ? 'flex flex-row-reverse justify-between items-center' : 'p-5 border-r-[40px] border-gray-200'}`} key={'item'+index}>
            <div className={`${!item.expand ? 'flex flex-row-reverse justify-end items-center' : 'absolute top-0 -right-10 w-10'}`}>
                <div className={`${!item.expand ? '' : 'py-2'}`}>{handler}</div>
                <button type="button" className="bg-gray-400 hover:bg-red-500 text-white flex justify-center items-center w-10 h-10" onClick={()=>_removeItem(item.id)}><X size="16" /></button>
                <button type="button" className="bg-gray-300 hover:bg-black text-white flex justify-center items-center w-10 h-10" onClick={()=>_updateValue('expand', item.expand ? false : true, index)}>{item.expand ? <ChevronUp size="16"/> : <ChevronDown size="16" />}</button>
            </div>
            {item.expand ? 
            <div className="flex flex-col md:flex-row gap-6" onClick={(e)=> e.stopPropagation()}>
                <div className="w-full md:1/3 lg:w-1/4 xl:1/5">
                    {item.fileName != '' && <img src={process.env.REACT_APP_UPLOAD_URL+'/'+item.fileName+'_400.jpg'} alt=""/>}
                </div>
                <div className="w-full md:2/3 lg:w-3/4 xl:4/5">
                    <div className={`formGroup`}>
                        <label>Title</label>
                        <div className="relative">
                            <input type={'text'} name="title" className={`formControl`} value={item.title} onChange={(e)=>{ _updateValue('title', e.target.value, index) }}/>
                        </div>
                    </div>
                    <div className={`formGroup`}>
                        <label>Link</label>
                        <div className="relative">
                            <input type={'text'} name="link" className={`formControl`} value={item.link} onChange={(e)=>{ _updateValue('link', e.target.value, index) }}/>
                        </div>
                    </div>
                    <div className="mt-6 flex gap-2 justify-start items-center">
                        <button type="button" className="button secondary" onClick={() => { _mediaOpen(item.id) }}><Plus/> Select/Upload image</button>
                    </div>
                </div>
            </div>
            :
            <h4 className="pl-4 py-2 font-bold">{item.title}</h4>
            }
        </div>
      );
  
    }


    //
    //
    React.useEffect(()=>{
        const init = async () =>{
            const get = await _apiGETone('/misc/single/'+id, true)
            if(!get.error){
                console.log(get.data)
                setResponseData(get.data)
            }
            setLoading(false)
        }
        init()
    },[])


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Instagram | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        title="Instagram"
                    >
                        <button type="button" onClick={()=> _submit()} className="button primary sm hidden md:flex"><Save size="16" className="mr-2"/> Save</button>
                        <a href="#" className="button dark sm ml-2 hidden md:flex" onClick={()=>_addItem()}><Plus size={16}/> Add item</a>
                    </TopBar>
                    <div className="_inner">
                        {/* START */}

                        <div className='flex gap-1 md:hidden mb-4'>
                            <button type="button" onClick={()=> _submit()} className="button primary sm w-full"><Save size="16" className="mr-2"/> Save</button>
                            <a href="#" className="button dark sm w-full" onClick={()=>_addItem()}><Plus size={16}/> Add item</a>
                        </div>

                        {responseData.items && 
                        <Nestable
                            items={responseData.items}
                            renderItem={_item}
                            onChange={(event)=> setResponseData({...responseData, items:event.items}) }
                            maxDepth={0}
                            handler={<Handler />}
                        />
                        }
                        {responseData.items && responseData.items.length===0 && !isLoading && <p className="text-center py-10">There's no items, use <strong>Add item</strong> add new one.</p>}

                        {/* END */}
                    </div>
                </div>
            </Layout>

            {mediaState && <Media
                state={mediaState}
                index={mediaIndex}
                close={()=> _mediaClose()}
                action={_insertMedia}
              />
            }

        </Fragment>
    );

}
