import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom';
import { Loader, Plus, Move, X, Save, ChevronDown, ChevronUp, ChevronLeft } from 'react-feather';
import Nestable from 'react-nestable';


// components
import Layout from '../../components/Layout'
import Sidebar from '../../components/Sidebar'
import TopBar from '../../components/TopBar'


// controllers
import { _apiGETone, _apiPOST, _apiPUT } from '../../controllers/Api';
import { _toaster } from '../../controllers/Helpers';






//
//
export default function PageInstagram(props){

    const auth = useSelector(state => state.auth);
    const { id } = useParams();
    const history = useHistory()

    const fields = {
        _id: null,
        title: '',
        values: []
    };

    const [isLoading, setLoading] = React.useState(true);
    const [responseData, setResponseData] = React.useState(fields);


    //
    //
    const appReducer = (state = [], action) => {
        switch (action.type) {
        case 'ADD':
            return [
            ...state, action.data
            ];
        case 'DELETE':
            return state.filter(x => x.id !== action.data);
        case 'UPDATE':
            return state.map(x => x.id === action.data.id
            ? {...x, [action.data.name]: action.data.value}
            : x
            );
        case 'SORT-AZ':
            return [...state].sort((current,next)=>{ return current.title.localeCompare(next.title)});
        case 'SORT-ZA':
            return [...state].sort((current,next)=>{ return next.title.localeCompare(current.title)});
        case 'SORT-ID-ASC':
            return [...state].sort((current,next)=>{ return current.value.localeCompare(next.value)});
        case 'SORT-ID-DESC':
            return [...state].sort((current,next)=>{ return next.value.localeCompare(current.value)});
        case 'SET':
            return action.data;
        default:
            return state;
        }
    };
    const [state, dispatch] = React.useReducer(appReducer, []);


    //
    //
    const _submit = async() => {

        let formData = {...responseData, values:state}

        setLoading(true);
        if(id==='new'){
                
            const insert = await _apiPOST('/shop/attributes/insert/', formData)
            if(!insert.error){
                _toaster('Success', insert.message, 'success')
                history.goBack()
            }


        }else{

            const update = await _apiPUT('/shop/attributes/update/'+id, formData)
            if(!update.error){
                _toaster('Success', update.message, 'success')
                history.goBack()
            }

        }
        setLoading(false);


    } 
    


    //
    //
    const _addItem = () => {
        dispatch({type: 'ADD', data: { id: new Date().getTime(), title: '', value: 'attr'+(new Date().getTime()), expand:true}});
    }


    //
    //
    React.useEffect(()=>{
        const init = async () =>{
            const get = await _apiGETone('/shop/attributes/single/'+id, true)
            if(!get.error){
                setResponseData(get.data)
                dispatch({ type: 'SET', data: get.data.values });
            }
            setLoading(false)
        }
        init()
    },[])



    //
    //
    const Handler = () => {
        return <button type="button" className="flex justify-center items-center w-10 h-10 text-gray-500 hover:text-black"><Move size="16"/></button>;
    };


    //
    //;
    const _item = ({item, index, handler}) => {
      return (
        <div className={`box relative my-5 ${!item.expand ? 'flex flex-row-reverse justify-between items-center' : 'p-5 border-r-[40px] border-gray-200'}`} key={'item'+index}>
            <div className={`${!item.expand ? 'flex flex-row-reverse justify-end items-center' : 'absolute top-0 -right-10 w-10'}`}>
                <div className={`${!item.expand ? '' : 'py-2'}`}>{handler}</div>
                <button type="button" className="bg-gray-400 hover:bg-red-500 text-white flex justify-center items-center w-10 h-10" onClick={()=> dispatch({ type: 'DELETE', data: item.id })}><X size="16" /></button>
                <button type="button" className="bg-gray-300 hover:bg-black text-white flex justify-center items-center w-10 h-10" onClick={()=>dispatch({ type: 'UPDATE', data: {id: item.id, name: 'expand', value:item.expand ? false : true, index:index }})}>{item.expand ? <ChevronUp size="16"/> : <ChevronDown size="16" />}</button>
            </div>
            {item.expand ?  
            <div className="grid grdi-cols-1 lg:grid-cols-2 gap-6">
                    <div className={`formGroup`}>
                        <label>Title</label>
                        <div className="relative">
                            <input type={'text'} name="title" className={`formControl`} value={item.title} onChange={(e)=>{ dispatch({ type: 'UPDATE', data: {id: item.id, name: 'title', value:e.target.value, index:index }}) }}/>
                        </div>
                    </div>
                    <div className={`formGroup`}>
                        <label>Value</label>
                        <div className="relative">
                            <input type={'text'} name="value" className={`formControl`} value={item.value} disabled={true}/>
                        </div>
                    </div>
            </div>
            :
            <h4 className="pl-4 py-2 font-bold">{item.title}</h4>
            }
        </div>
      );
  
    }


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Products / Attributes | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Products / Attributes"
                        title={responseData.title}
                    >
                        <button type="button" className="button dark sm hidden md:flex mr-2" onClick={()=> history.goBack()}><ChevronLeft size={16}/> Back</button>
                        <button type="button" onClick={()=> _submit()} className="button primary sm hidden md:flex"><Save size="16" className="mr-2"/> Save</button>
                        <a href="#" className="button dark sm ml-2 hidden md:flex" onClick={()=>_addItem()}><Plus size={16}/> Add item</a>
                    </TopBar>
                    <div className="_inner">
                        {/* START */}

                        <div className='flex gap-1 md:hidden mb-4'>
                            <button type="button" className="button dark sm w-full" onClick={()=> history.goBack()}><ChevronLeft size={16}/> Back</button>
                            <button type="button" onClick={()=> _submit()} className="button primary sm w-full"><Save size="16" className="mr-2"/> Save</button>
                            <a href="#" className="button dark sm w-full" onClick={()=>_addItem()}><Plus size={16}/> Add item</a>
                        </div>

                        <div className={`formGroup`}>
                            <label>Attribute name</label>
                            <div className="relative">
                                <input type={'text'} name="title" className={`formControl`} defaultValue={responseData.title} onChange={(e)=>{setResponseData({...responseData, title:e.target.value}); }}/>
                            </div>
                        </div>

                        {isLoading && <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div>}

                        {state && state.length>0 && 
                        <>
                        <div className="flex justify-end items-center text-sm gap-1">
                            <span>Sort by:</span>
                            <button type="button" onClick={()=> dispatch({ type: 'SORT-AZ', data:null })} className="button secondary xs">A-Z</button>
                            <button type="button" onClick={()=> dispatch({ type: 'SORT-ZA' })} className="button secondary xs">Z-A</button>
                            <button type="button" onClick={()=> dispatch({ type: 'SORT-ID-ASC' })} className="button secondary xs">From Date</button>
                            <button type="button" onClick={()=> dispatch({ type: 'SORT-ID-DESC' })} className="button secondary xs">To Date</button>
                        </div>
                        <Nestable
                            items={state}
                            renderItem={_item}
                            onChange={(event)=> dispatch({ type: 'SET', data: event.items }) }
                            maxDepth={0}
                            handler={<Handler />}
                        />
                        </>
                        }
                        {state && state.length===0 && !isLoading && <p className="text-center py-10">There's no items, use <strong>Add item</strong> add new one.</p>}

                        {/* END */}
                    </div>
                </div>
            </Layout>

        </Fragment>
    );

}
