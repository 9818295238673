import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
    ChevronDown,
    ChevronUp,
    BarChart2,
    ShoppingCart,
    DollarSign,
    Image,
    Users,
    Instagram,
    Award
} from 'react-feather';


// controllers
import { _initials, _isActivePage } from '../../controllers/Helpers'


//
//
function NavItem(props){

    const item = props.data
    const location = useLocation()
    const [on, toggle] = useToggle(false);


    //
    //
    const _isActive = (toggleState, url) => {

        if(Array.isArray(url)){
            for (let index = 0; index < url.length; index++) {
                if(_isActivePage(url[index].url, location)){ return true } 
            }
        }else{
            if(_isActivePage(url, location)){ return true } 
        }

        if(toggleState){
            return true
        }

        return false
    }


    //
    //
    return (
        <li className={`${item.type} ${_isActive(on, item.children ? item.children : item.url) ? 'active' : ''}`}>
            {item.type==='title' && item.title}
            {item.type==='link' && <Link to={item.url}>{item.icon && item.icon} <span>{item.title}</span></Link>}
            {item.type==='dropdown' && 
                <>
                    <button onClick={toggle} className="flex justify-between items-center w-full">
                        <div className="flex justify-start items-center">
                            {item.icon && item.icon}
                            <span>{item.title}</span>
                        </div>
                        {!_isActive(on, item.url) ? <ChevronDown size={16}/> : <ChevronUp size={16}/>}
                    </button>
                    {item.children?.length>0 && 
                        <ul className={`children ${_isActive(on, item.children) ? 'show' : 'hide'}`}>
                            {item.children.map((children,index)=>{
                                if(children.hidden){ return null }
                                return (
                                    <Link to={children.url} key={index}>{children.title}</Link>
                                )
                            })}
                        </ul>
                    }
                </>
            }
        </li>
    )

}


//
//
export default function Sidebar(props){

    const auth = props.auth;

    const links = [
        {
            type:'link', 
            url: '/dashboard',
            title: 'Dashboard',
            icon: <BarChart2/>
        },
        {
            type:'title', 
            title:'store'
        },
        {
            type:'dropdown', 
            title: 'Transactions',
            icon: <DollarSign/>,
            children:[
                {
                    title:'Orders',
                    url:'/transactions/orders'
                },
                {
                    title:'Payouts',
                    url:'/transactions/payouts'
                },
                // {
                //     title:'Donations',
                //     url:'/transactions/donations'
                // }
            ]
        },
        {
            type:'dropdown', 
            title: 'Products',
            icon: <ShoppingCart/>,
            children:[
                {
                    title:'All products',
                    url:'/products/all'
                },
                {
                    title:'Categories',
                    url:'/products/categories/none'
                },
                {
                    title:'Attributes',
                    url:'/products/attributes'
                },
                {
                    title:'Shipping options',
                    url:'/products/shipping'
                },
                {
                    title:'Change ownership',
                    url:'/products/change-ownership'
                },
                {
                    title:'Attributes Single',
                    url:'/products/attribute',
                    hidden:true
                },
                {
                    title:'Attributes Single',
                    url:'/product/',
                    hidden:true
                },
            ]
        },
        {
            type:'title', 
            title:'site'
        },
        {
            type:'link', 
            url: '/media',
            title: 'Media manager',
            icon: <Image/>
        },
        {
            type:'link', 
            url: '/instagram',
            title: 'Instagram',
            icon: <Instagram/>
        },
        {
            type:'link', 
            url: '/top-brands',
            title: 'Top brands',
            icon: <Award/>
        },
        {
            type:'link', 
            url: '/users',
            title: 'Users & Admins',
            icon: <Users/>
        },
        // {
        //     type:'dropdown', 
        //     title: 'Site content',
        //     icon: <Box/>,
        //     children:[
        //         {
        //             title:'Pages',
        //             url:'/pages'
        //         },
        //         {
        //             title:'Carousels',
        //             url:'/carousels'
        //         },
        //         {
        //             title:'Navigations',
        //             url:'/navigations'
        //         },
        //         {
        //             title:'Top brands',
        //             url:'/top-brands'
        //         },
        //         {
        //             title:'Instagram',
        //             url:'/instagram'
        //         }
        //     ]
        // },
    ]


    //
    //
    return (
        <div className={`_sidebar ${props.status ? ' ' : ' hide'}`}>

            <div className="px-4 py-3 leading-tight bg-black bg-opacity-20 mb-4">
                <p className="text-white uppercase font-light text-sm pb-0">{process.env.REACT_APP_NAME}</p>
                <a href={process.env.REACT_APP_WEB_URL} target="_blank" rel="noreferrer" className="text-xs text-primary">Visit site &rarr;</a>
            </div>

            <div className="p-4 flex justify-start items-center">
                <div className="w-12 h-12 bg-white rounded-full flex justify-center items-center text-lg uppercase">{_initials(auth.response)}</div>
                <div className="pl-2 leading-tight text-white">
                    <p className="text-sm">{auth.response.firstName} {auth.response.lastName}</p>
                    <p>
                        <Link to={'/user/'+auth.response._id} className="text-primary text-xxs uppercase">My profile</Link>
                        <span className="px-1 text-xxs">|</span>
                        <Link to={'/'} className="text-primary text-xxs uppercase">Logout</Link>
                    </p>
                </div>
            </div>

            <div className="_navigation">
                <ul>
                    {links.map((item,index)=>{
                        return <NavItem data={item} key={index}/>
                    })}
                </ul>
            </div>

        </div>
    )

}
