import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Plus, Move, X, Save, Loader } from 'react-feather';



// components
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import TopBar from '../components/TopBar'
import Loading from '../components/Loading'


// controllers
import { _apiGETmulti, _apiPOST } from '../controllers/Api';
import { _toaster } from '../controllers/Helpers';


//
//
export default function PageChangeOwnership(props){

    const auth = useSelector(state => state.auth);

    const node1 = React.useRef();
    const node2 = React.useRef();

    const [isLoading, setLoading] = React.useState(true);
    const [ searchLoading1, setSearchLoading1 ] = React.useState(false)
    const [ searchLoading2, setSearchLoading2 ] = React.useState(false)
    const [ searchIsOpen1, setSearchIsOpen1 ] = React.useState(false)
    const [ searchIsOpen2, setSearchIsOpen2 ] = React.useState(false)

    const [ searchProduct, setSearchProduct ] = React.useState('')
    const [ searchMember, setSearchMember ] = React.useState('')
    const [ selectedProduct, setSelectedProduct ] = React.useState(null)
    const [ selectedMember, setSelectedMember ] = React.useState(null)

    const [ matchedProducts, setMatchedProducts ] = React.useState(null)
    const [ matchedMembers, setMatchedMembers ] = React.useState(null)



    //
    //
    const _submit = async() => {
        setLoading(true)
        const changeOwner = await _apiPOST('/shop/products/ownership/'+selectedProduct._id, {ownerId:selectedMember._id})
        if(!changeOwner.error){
            _toaster('Success', changeOwner.message, 'success')
            setMatchedMembers(null);
            setSearchIsOpen2(false);
            setMatchedProducts(null);
            setSearchIsOpen1(false);
            setSelectedMember(null)
            setSelectedProduct(null)
        }
        setLoading(false)
    }



    //
    //
    const _getSearchMember = async() => {

        setSearchLoading2(true);

        const searching = await _apiGETmulti('/user/query/', {
            search: searchMember,
            transfer: true,
            limit:5
        })

        if(!searching.error){

            if(searching.total !== 0){
                setMatchedMembers(searching.data);
                setSearchIsOpen2(true);
            }else{
                setMatchedMembers(null);
                setSearchIsOpen2(false);
            }

        }

        setSearchLoading2(false);

    }


    //
    //
    const _getSearchProduct = async() => {

        setSearchLoading1(true);

        const searching = await _apiGETmulti('/public/query-products/', {
            filter: [{
                search: searchProduct
            }],
            transfer: true,
            limit:5
        })

        if(!searching.error){

            if(searching.total !== 0){
                setMatchedProducts(searching.data);
                setSearchIsOpen1(true);
            }else{
                setMatchedProducts(null);
                setSearchIsOpen1(false);
            }

        }

        setSearchLoading1(false);

    }



    //
    //
    const _addSelectedMember = (item) =>{
        setSelectedMember(item)
        setSearchIsOpen2(false);
        setSearchMember('');
    }


    //
    //
    const _searchMember = () => {
        if(matchedMembers && matchedMembers.length>0){
        return matchedMembers.map((item,i)=>{
            return(
            <div key={i}><div>{item.storeName}</div> <a href={'#'} onClick={()=> _addSelectedMember(item)}><Plus/> SELECT</a></div>
            );
        })
        }else{
        return null;
        }
    }


    //
    //
    const _addSelectedProduct = (item) =>{
        setSelectedProduct(item)
        setSearchIsOpen1(false);
        setSearchProduct('');
    }


    //
    //
    const _searchProducts = () => {
        if(matchedProducts && matchedProducts.length>0){
        return matchedProducts.map((item,i)=>{
            return(
            <div key={i}><div>{item.title}<br/><span>sku: {item.sku}</span></div> <a href={'#'} onClick={()=> _addSelectedProduct(item)}><Plus/> SELECT</a></div>
            );
        })
        }else{
        return null;
        }
    }


    //
    //
    const handleClick2 = e => {
        if (node2.current.contains(e.target)) {
            return;
        }
        setSearchIsOpen2(false);
        setSearchProduct('');
    };


    //
    //
    const handleClick1 = e => {
        if (node1.current.contains(e.target)) {
            return;
        }
        setSearchIsOpen1(false);
        setSearchMember('');
    };

    //
    //
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClick1);
        document.addEventListener("mousedown", handleClick2);
        return () => {
            document.removeEventListener("mousedown", handleClick1);
            document.removeEventListener("mousedown", handleClick2);
        };
    });


    //
    //
    React.useEffect(()=>{
        const timeoutId = setTimeout(() => searchProduct !== '' ? _getSearchProduct() : null, 500);
        return () => clearTimeout(timeoutId);
    },[searchProduct])


    //
    //
    React.useEffect(()=>{
        const timeoutId = setTimeout(() => searchMember !== '' ? _getSearchMember() : null, 500);
        return () => clearTimeout(timeoutId);
    },[searchMember])


    //
    //
    React.useEffect(()=>{
        const init = async () =>{
            // const get = await _apiGETone('/misc/single/'+id, true)
            // if(!get.error){
            //     console.log(get.data)
            //     setResponseData(get.data)
            // }
            setLoading(false)
        }
        init()
    },[])


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Products / Change ownership | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Products"
                        title="Change ownership"
                    />
                    <div className="_inner">
                        {/* START */}

                        <div className='flex flex-col sm:flex-row gap-6'>

                            <div className="w-full">
                                <div className='box p-6 w-full'>
                                    <div className="flex justify-between items-center">
                                        <h4 className="mb-2 font-bold">Search for product</h4>
                                        {searchLoading1 && <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div>}
                                    </div>
                                    <div className='formGroup'>
                                        <div className="searchProductDDwraper">
                                            <div className="formGroup">
                                                <input 
                                                    type="text" 
                                                    className="formControl" 
                                                    value={searchProduct}
                                                    onChange={e => { setSearchProduct(e.target.value) }}
                                                    placeholder="Type to search products..."
                                                    autoComplete="off"
                                                />
                                            </div>
                                        
                                            <div className={searchIsOpen1 ? 'searchProductDD transition active' : 'searchProductDD transition'} ref={node1}>{_searchProducts()}</div>
                                        </div>
                                    </div>
                                </div>
                                {selectedProduct &&
                                    <div className='p-6 grid grdi-cols-1 md:grid-cols-2 gap-4'>
                                        {selectedProduct.featuredImage != '' && <img src={process.env.REACT_APP_UPLOAD_URL+'/'+selectedProduct.featuredImage+'_400.jpg'} alt=""/>}
                                        <div className='w-full'>
                                            <button type="button" className='button sm secondary w-full' onClick={()=> setSelectedProduct(null)}>remove</button>
                                            <small className='block uppercase text-gray-500 mt-5 mb-2'>Selected product</small>
                                            <h4 className='text-lg font-medium'>{selectedProduct.title} | {process.env.REACT_APP_CURRENCY+parseFloat(selectedProduct.price).toFixed(2)}</h4>
                                            <p className='text-sm'>SKU: {selectedProduct.sku}</p>
                                            <p className='text-sm'>owner: <strong>{selectedProduct.store[0].storeName}</strong></p>
                                            <p className='text-sm'>group: {selectedProduct.group}</p>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="w-full">
                                <div className='box p-6 w-full'>
                                    <div className="flex justify-between items-center">
                                        <h4 className="mb-2 font-bold">Search for new owner/store</h4>
                                        {searchLoading2 && <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div>}
                                    </div>
                                    <div className='formGroup'>
                                        <div className="searchProductDDwraper">
                                            <div className="formGroup">
                                                <input 
                                                    type="text" 
                                                    className="formControl" 
                                                    value={searchMember}
                                                    onChange={e => { setSearchMember(e.target.value) }}
                                                    placeholder="Type to search members..."
                                                    autoComplete="off"
                                                />
                                            </div>
                                        
                                            <div className={searchIsOpen2 ? 'searchProductDD transition active' : 'searchProductDD transition'} ref={node2}>{_searchMember()}</div>
                                        </div>
                                    </div>
                                </div>
                                {selectedMember &&
                                    <div className='p-6 grid grdi-cols-1 md:grid-cols-2 gap-4'>
                                        <div className='w-full text-left md:text-right flex flex-col justify-start md:justify-end'>
                                            <button type="button" className='button sm secondary w-full' onClick={()=> setSelectedMember(null)}>remove</button>
                                            <small className='uppercase text-gray-500 mt-5 mb-2'>Selected member</small>
                                            <h4 className='text-lg font-medium'>{selectedMember.storeName}</h4>
                                            <p className='text-sm'>{selectedMember.firstName} {selectedMember.lastName}</p>
                                            <p className='text-sm'>{selectedMember.email}</p>
                                            <p className='text-sm'>{selectedMember.phone}</p>
                                        </div>
                                        {selectedMember.storeImage && selectedMember.storeImage != '' && <img src={process.env.REACT_APP_UPLOAD_URL+'/'+selectedMember.storeImage+'_400.jpg'} alt=""/>}
                                    </div>
                                }
                            </div>

                        </div>
                        
                        {selectedMember && selectedProduct && <hr/>}
                        {selectedMember && selectedProduct && 
                            <div className='max-w-md mx-auto'>
                                <button type="button" className='button primary mt-10 w-full' onClick={()=> _submit()}>Confirm ownership migration</button>
                            </div>
                        }

                        {/* END */}
                    </div>
                </div>
            </Layout>

            <Loading status={isLoading}/>

        </Fragment>
    );

}
