import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Loader } from 'react-feather';
import {useDropzone} from 'react-dropzone'
import { HexColorPicker, HexColorInput } from "react-colorful";



// components
import Layout from '../components/Layout'
import Loading from '../components/Loading'
import Sidebar from '../components/Sidebar'
import TopBar from '../components/TopBar'
import Search from '../components/Search'
import InlineEdit from '../components/InlineEdit';


// controllers
import { _apiGETmulti, _apiPUT, _apiPOST, _apiDELETE } from '../controllers/Api';
import { _copy, _toaster, _timeout } from '../controllers/Helpers'


//
//
export default function PageMediaManager(props){

    const auth = useSelector(state => state.auth);

    const fields = {
        limit: 20,
        offset: 0,
        orderBy: 'dateCreated|desc',
        search: ''
    }

    const topRef = React.useRef();
    const bottomRef = React.useRef();

    const [isLoading, setLoading] = React.useState(true);
    const [isUploading, setUploading] = React.useState(false);
    const [total, setTotal] = React.useState({});
    const [responseData, setResponseData] = React.useState([]);
    const [requestData, setRequestData] = React.useState(fields);

    const [slazzerApi, setSlazzerApi] = React.useState(props.slazzer ? true : false);
    const [slazzerBg, setSlazzerBg] = React.useState(process.env.REACT_APP_COLORBG);

    const node = React.useRef(null);
    const [ isPickerOpen, setIsPickerOpen ] = React.useState(false);


    //
    //
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }
        setIsPickerOpen(false);
    };


    //
    //
    React.useEffect(() => {
        slazzerApi && document.addEventListener("mousedown", handleClick);
        return () => {
            slazzerApi && document.removeEventListener("mousedown", handleClick);
        };
    });
  


    //
    //
    const _createRequest = (field, value) => {
        if(field==='search'){
            setRequestData({...requestData, [field]:value, offset:0})
        }else{
            setRequestData({...requestData, [field]:value})
        }
    }


    //
    //
    const _handleColorChange = (value) => {
        setSlazzerBg(value)
    }


    //
    //
    const onDrop = React.useCallback(async(acceptedFiles) => {

        setUploading(true);

        let formData = new FormData();
        formData.append('slazzer', slazzerApi);
        formData.append('slazzerBg', slazzerBg);

        for (var i = 0; i < acceptedFiles.length; i++) {
            formData.append('files[]', acceptedFiles[i]);
            formData.append('names[]', acceptedFiles[i].name);
        }

        const upload = await _apiPOST('/media/insert/', formData)
        if(upload.error){
            _toaster('Error', upload.error.message, 'error')
            setUploading(false);
            return
        }

        setResponseData(responseData => [...upload.images, ...responseData])
        _toaster('Success', 'New image is successfully uploaded.', 'success')
        setUploading(false);
        await _timeout(100)
        topRef.current.scrollIntoView();

    }, [slazzerApi, slazzerBg]);
    const {getRootProps, getInputProps} = useDropzone({onDrop});


    //
    //
    const _updateImage = async(image, value) => {

        let newData = responseData
        let item = newData.findIndex(x => x._id === image._id);
        if(item < 0){ return false; }
        if(newData[item].alt === value){ return false; }

        const update = await _apiPUT('/media/update/'+image._id, {alt: value})
        if(update.error){
            _toaster('Error', update.error.message, 'error')
            return
        }

        setResponseData(newData)
        _toaster('Success', update.message, 'success')

    }


    //
    //
    const _deleteImage = async(image) => {

        setLoading(true);

        const remove = await _apiDELETE('/media/delete/'+image._id)
        if(remove.error){
            _toaster('Error', remove.error.message, 'error')
            setLoading(false);
            return
        }

        setResponseData(responseData => responseData.filter(id => id._id !== image._id));
        _toaster('Success', 'Image deleted successfully.', 'success')
        setLoading(false);

    }


    //
    //
    const init = async (data=requestData) =>{

        setLoading(true)
        const get = await _apiGETmulti('/media/query/', data)
        if(!get.error){
            setResponseData(data.offset>0 ? [...responseData, ...get.data] : get.data)
            setTotal(get.total)
        }

        setLoading(false)
        if(data.offset>0){
            await _timeout(500)
            bottomRef.current.scrollIntoView();
        }

    }


    //
    //
    React.useEffect(()=>{
        init(requestData)
    },[requestData])


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Media manager | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        title="Media manager"
                    >
                        <div className="formGroup mr-2 hidden md:flex">
                            <div className="switch mt-1">
                                <input type="checkbox" name="type" value="" checked={slazzerApi} onChange={()=> setSlazzerApi(!slazzerApi)}/>
                                <div className="mr-2"><i></i></div>
                                <label>Enable Slazzer Api</label>
                            </div>
                        </div>
                        {slazzerApi &&
                            <div className={`ddColorPicker ${isPickerOpen ? 'active' : ''} hidden md:flex`} ref={node}>
                                <button type="button p-4" className="button" style={{backgroundColor:slazzerBg}} onClick={()=> setIsPickerOpen(!isPickerOpen)}></button>
                                <div>
                                <HexColorPicker color={slazzerBg} onChange={_handleColorChange} alpha="true" prefixed="true"/>
                                <HexColorInput color={slazzerBg} onChange={_handleColorChange} />
                                <button type="button" className="button dark sm w-full mt-2" onClick={()=>setIsPickerOpen(false)}>Close</button>
                                </div>
                            </div>
                        }
                        <div className="button dark sm mr-2 hidden md:flex" {...getRootProps()}>
                            <span>Select file for Upload</span>
                            <input {...getInputProps()}/>
                        </div>
                        <Search 
                            isLoading={isLoading}
                            onChange={(value)=>_createRequest('search',value)}
                            className="hidden md:flex"
                        />
                    </TopBar>

                    <div className="_inner">
                        {/* START */}

                        <div className='flex justify-between item-center gap-1 md:hidden mb-4'>
                            <div className="formGroup">
                                <div className="switch mt-1">
                                    <input type="checkbox" name="type" value="" checked={slazzerApi} onChange={()=> setSlazzerApi(!slazzerApi)}/>
                                    <div className="mr-2"><i></i></div>
                                    <label>Enable Slazzer Api</label>
                                </div>
                            </div>
                            {slazzerApi &&
                                <div className={`ddColorPicker ${isPickerOpen ? 'active' : ''}`} ref={node}>
                                    <button type="button p-4" className="button" style={{backgroundColor:slazzerBg}} onClick={()=> setIsPickerOpen(!isPickerOpen)}></button>
                                    <div>
                                    <HexColorPicker color={slazzerBg} onChange={_handleColorChange} alpha="true" prefixed="true"/>
                                    <HexColorInput color={slazzerBg} onChange={_handleColorChange} />
                                    <button type="button" className="button dark sm w-full mt-2" onClick={()=>setIsPickerOpen(false)}>Close</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='md:hidden mb-4'>
                            <div className="button dark sm w-full" {...getRootProps()}>
                                <span>Select file for Upload</span>
                                <input {...getInputProps()}/>
                            </div>
                        </div>
                        <div className='md:hidden mb-4'>
                            <Search 
                                isLoading={isLoading}
                                onChange={(value)=>_createRequest('search',value)}
                                className="w-full"
                            />
                        </div>


                        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-8 gap-4" ref={topRef}>
                        {
                            responseData.length>0 && responseData.map((image,index)=>{
                                return(
                                    <div className="mediaCard" key={index}>
                                        <div className="image" style={{backgroundImage:`url(${process.env.REACT_APP_UPLOAD_URL+'/'+image.fileName+'_400.'+image.extension})`}}>
                                            <div className="flex justify-between items-center">
                                                <button type="button" className="button red xs" onClick={()=>_deleteImage(image)}>delete</button>
                                                <button type="button" className="button secondary xs" onClick={()=> _copy(process.env.REACT_APP_UPLOAD_URL+'/'+image.fileName+'_800.'+image.extension)}>copy url</button>
                                            </div>
                                        </div>
                                        <div>
                                            <InlineEdit text={image.alt} onSave={(val)=> _updateImage(image,val) }/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {isLoading ? 
                            <div className="_loadingSmall py-10"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div>
                            :
                            <button className="mediaCard py-10 uppercase bg-secondary text-black" ref={bottomRef} onClick={()=>_createRequest('offset', requestData.offset+requestData.limit)}>Load more</button>
                        }
                        </div>

                        {/* END */}
                    </div>
                </div>
            </Layout>


            <Loading status={isUploading}/>

        </Fragment>
    );

}
