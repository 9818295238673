import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useHistory, useParams, Link } from 'react-router-dom';
import { Edit3, Move, X, Save, List, Loader, ChevronLeft } from 'react-feather';
import Nestable from 'react-nestable';
import { Form } from '@unform/web'
import * as Yup from 'yup';



// components
import Layout from '../components/Layout'
import Sidebar from '../components/Sidebar'
import TopBar from '../components/TopBar'
import Input from '../components/Forms/Input'


// controllers
import { _apiGETmulti, _apiPOST, _apiPUT, _apiDELETE } from '../controllers/Api';
import { _toaster } from '../controllers/Helpers';


//
//
export default function PageProductsCategories(props){

    const auth = useSelector(state => state.auth);
    const formRef = React.useRef(null);
    const history = useHistory()
    const { id } = useParams();

    const [ formType, setFormType ] = React.useState(null)

    const [isLoading, setLoading] = React.useState(true);
    const [reordered, setReorder] = React.useState(false);



    //
    //
    const appReducer = (state = [], action) => {
        switch (action.type) {
        case 'SET':
            return action.data;
        default:
            return state;
        }
    };
    const [state, dispatch] = React.useReducer(appReducer, []);


    //
    //
    const _get = async() => {
        setLoading(true)
        const get = await _apiGETmulti('/taxonomies/query-by-parent/', {
            type: 'products',
            parentId:id
        })
        if(!get.error){
            dispatch({ type: 'SET', data: get });
        }
        setLoading(false)
    }


    //
    //
    const handleSubmit = React.useCallback(async(data) => {
        try {
    
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
                title: Yup.string().required()
            });
            await schema.validate(data, {
                abortEarly: false,
            });


            let formData = {title:data.title, type: 'products', parentId:id}

            setLoading(true);
            if(formType==='add'){
                
                const insert = await _apiPOST('/taxonomies/insert/', formData)
                if(!insert.error){
                    _toaster('Success', insert.message, 'success')
                    setFormType(null)
                    await _get()
                }

            }
            if(formType==='edit'){

                const update = await _apiPUT('/taxonomies/update/'+data._id, formData)
                if(!update.error){
                    _toaster('Success', update.message, 'success')
                    setFormType(null)
                    await _get()
                }

            }
            setLoading(false);
    
        } catch (err) {
    
          const validationErrors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
                console.log(error.path,error.message)
              validationErrors[error.path] = error.message;
            });
            formRef.current.setErrors(validationErrors);
          }
    
        }
    }, [id, formType]);


    //
    //
    const _reorder = async() => {

        setLoading(true);
        const update = await _apiPUT('/taxonomies/reorder/', state)
        if(!update.error){
            _toaster('Success', update.message, 'success')
            setFormType(null)
            setReorder(false);
        }
        setLoading(false);
    }


    //
    //
    const _delete = async(categoryID) => {

        if (!window.confirm('Are you sure you want to delete?')){
            return
        }

        setLoading(true);
        const d = await _apiDELETE('/taxonomies/delete/'+categoryID)
        if(!d.error){
            _toaster('Success', d.message, 'success')
            setFormType(null)
            setReorder(false);
            await _get()
        }
        setLoading(false);
    }



    //
    //
    const Handler = () => {
        return <button type="button" className="flex justify-center items-center w-10 h-10 text-gray-500 hover:text-black"><Move size="16"/></button>;
    };


    //
    //;
    const _item = ({item, index, handler}) => {
      return (
        <div className={`box relative my-5 flex flex-row-reverse justify-between items-center`} key={'item'+index}>
            <div className={`flex flex-row-reverse justify-end items-center`}>
                <div>{handler}</div>
                <button type="button" className="bg-gray-400 hover:bg-red-500 text-white flex justify-center items-center w-10 h-10" onClick={()=>_delete(item._id)}><X size="16" /></button>
                <button type="button" className="bg-gray-300 hover:bg-black text-white flex justify-center items-center w-10 h-10" onClick={()=>_setEdit(item)}><Edit3 size="16"/></button>
                <Link to={'/products/categories/'+item._id} className="bg-gray-200 hover:bg-black text-black hover:text-white flex justify-center items-center w-10 h-10"><List size="16"/></Link>
            </div>
            <h4 className="pl-4 py-2 font-bold">{item.title} <small className="font-normal">({item.total})</small></h4>
        </div>
      );
  
    }


    //
    //
    const _title = () => {
        // if(id === 'none'){
        //     return 'Top level'
        // }

        return 'categories'
    }


    //
    //
    const _setEdit = (item) => {
        setFormType('edit');
        formRef.current.setFieldValue('_id', item._id);
        formRef.current.setFieldValue('title', item.title);
    }


    //
    //
    React.useEffect(()=>{
        const init = async () =>{
            setFormType(null)
            await _get()
        }
        init()
    },[id])


    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Products / Categories | {process.env.REACT_APP_NAME}</title>
            </Helmet>


            <Layout className="_main default">
                <Sidebar auth={auth} />

                <div className="_content">
                    <TopBar 
                        auth={auth} 
                        overTitle="Products / Categories"
                        title={_title()}
                    >
                        <button type="button" className="button secondary sm ml-2 hidden md:flex" onClick={()=> {setFormType(!formType ? 'add' : null); !formType && formRef.current.reset();}}>{!formType ? 'add category' : 'cancel'}</button>
                        {id !== 'none' && <button type="button" className="button dark sm ml-2" onClick={()=> history.goBack()}><ChevronLeft size={16}/> Back</button>}
                        {reordered && <button type="button" onClick={()=> _reorder()} className="button primary sm hidden md:flex"><Save size="16" className="mr-2"/> Save reorder</button>}
                    </TopBar>
                    <div className="_inner">
                        {/* START */}

                        <div className='flex gap-1 md:hidden mb-4'>
                            <button type="button" className="button secondary sm w-full" onClick={()=> {setFormType(!formType ? 'add' : null); !formType && formRef.current.reset();}}>{!formType ? 'add category' : 'cancel'}</button>
                            {reordered && <button type="button" onClick={()=> _reorder()} className="button primary sm w-full"><Save size="16" className="mr-2"/> Save reorder</button>}
                        </div>


                        <div className={`box p-4 ${!formType ? 'hidden' : 'block'}`}>
                            <h4 className="mt-6 font-bold">{formType==='add' ? 'Add' : 'Edit'} Category</h4>
                            <Form ref={formRef} onSubmit={handleSubmit} className="w-full flex flex-col md:flex-row justify-center items-end gap-4" autoComplete="off">
                                <div className="hidden"><Input name="_id" label="_id"/></div>
                                <div className="w-full md:w-3/4"><Input name="title" label="Title"/></div>
                                <button type="submit" className="button primary w-full md:w-1/4 mb-3">SAVE</button>
                            </Form>
                        </div>

                        {isLoading && <div className="_loadingSmall"><Loader size={20} className="text-primary animate-spin"/><span>loading...</span></div>}
                        <Nestable
                            items={state}
                            renderItem={_item}
                            onChange={(event)=>{ dispatch({ type: 'SET', data: event.items }); setReorder(true) }}
                            maxDepth={0}
                            handler={<Handler />}
                        />
                        {state && state.length===0 && !isLoading && <p className="text-center py-10">There's no items, use <strong>Add item</strong> add new one.</p>}

                        {/* END */}
                    </div>
                </div>
            </Layout>

        </Fragment>
    );

}
