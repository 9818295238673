import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

export default function SingleSelect({ name, className, ...props }) {

    const selectRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);


    useEffect(() => {
      registerField({
        name: fieldName,
        ref: selectRef.current,
        getValue: (ref) => {

          // console.log('getValue',ref.state.value)
          return ref.props.isMulti
            ? ref.select.value?.map((option) => option._id) || []
            : ref.state.value;
        },
        setValue: (ref, value) => {
          // console.log('setValue',value)
          ref.value = value
          ref.select.setValue(value || null);
        },
        clearValue: (ref) => {
          ref.select.clearValue();
        }
      });
    }, [fieldName, registerField, props.isMulti]);

   
  return (
    <div className={`formGroup ${className ? className : ''}`}>
      {props.label && <label>{props.label}</label>}
      <ReactSelect
          ref={selectRef}
          defaultValue={defaultValue}
          isClearable={false}
          isSearchable={true}
          name={name}
          options={props.data}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option._id}
          className='react-select-container'
          classNamePrefix="react-select"
          autocomplette="off"
          {...props}
        />
        { error && <span className="error">{error}</span> }
    </div>
  );
}