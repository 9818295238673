import React from 'react';
import { Loader } from 'react-feather';


// controllers
import useQuery from '../../controllers/hooks/useQuery'


//
//
export default function Search(props){

    const query = useQuery();
    const [ searchText, setSearchText ] = React.useState(query.get('search') ? query.get('search') : '') 
    const [ init, setInit ] = React.useState(false)


    //
    //
    React.useEffect(() => {
        setInit(true)
    }, []);


    //
    //
    React.useEffect(() => {
        const timeoutId = setTimeout(() => init ? props.onChange(searchText) : null, 500 );
        return () => clearTimeout(timeoutId);
    }, [searchText]);


    //
    //
    return (
        <>
            <div className={`relative ${props.className ? props.className : ''}`}>
                <input 
                    type="text" 
                    name="q" 
                    value={searchText} 
                    onChange={(e)=>setSearchText(e.target.value)} 
                    placeholder="Search..."
                    className="py-2 px-3 rounded-sm bg-gray-100 text-sm w-full"
                />
                {props.isLoading && <div className="_loadingSmall absolute top-2 right-2"><Loader size={20} className="text-black animate-spin"/></div>}
            </div>
        </>
    )

}
