import axios from 'axios';
import {store} from './redux/Store'


export const API = (restricted=true, headers, cancel=null, config={}) => {
  const user = store.getState();

  let headersConfig = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': 0,
    'Content-Type': 'application/json',
    ...headers
  };
  if(restricted && user.auth.status){
    headersConfig = {...headersConfig, 'Authorization': 'Bearer '+user.auth.response.token}
  }

  let axiosConfig = {
    baseURL: process.env.REACT_APP_API,
    headers: headersConfig,
    cancelToken: cancel?.token,
    ...config
  }

  // console.log('axiosConfig',axiosConfig)

  return axios.create(axiosConfig);
}


export const APICancel = () => {
  return axios.CancelToken.source()
}



//
//
export const _apiPOST = async (route, data, header={}, restricted=true, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, header, cancel, config)
  .post(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}


//
//
export const _apiPUT = async (route, data, header={}, restricted=true, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, header, cancel, config)
  .put(route, data)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}


//
//
export const _apiDELETE = async (route, header={}, restricted=true, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, header, cancel, config)
  .delete(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}


//
//
export const _apiGETone = async (route, restricted=true, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, {}, cancel, config)
  .get(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}


//
//
export const _apiGETmulti = async (route, data, restricted=true, config={}) =>{

  const cancel = APICancel()

  return await API(restricted, {
    'X-MetaData': JSON.stringify(data)
  }, cancel, config)
  .get(route)
  .then(response => {
    return response.data
  }).catch(error => {
    return {error: error.response ? error.response.data : error};
  });

}