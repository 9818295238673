import React, { Fragment } from 'react';
import ProgressiveImage from "react-progressive-graceful-image";
import './style.scss';

export default function ProgressiveImageWrapper(props) {

  //
  //
  return (
    <Fragment>
      <div style={props.style} className="progressiveImage">
      <ProgressiveImage src={props.source} placeholder={props.thumb}>
      {(src, loading) => (
        <div
          style={{backgroundImage: `url(${src})`, opacity: loading ? 0.5 : 1}}
          className={loading ? 'transition blur' : 'transition'}
        >
        {loading && <span className="preload"><i className="spin"></i></span>}
        </div>
      )}
      </ProgressiveImage>
      </div>
    </Fragment>
  );
};
